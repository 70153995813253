import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { InspectorInspectionLink } from 'utils/link';
import { IndexListItemButton } from 'components/styles_components/list';
import Inspection from 'utils/inspection';
import State from 'components/inspection/state';
import Date from 'components/date';
import Result from 'components/inspection/result';

const ListItem = ({ inspection }) => {
  const navigate = useNavigate();

  return (
    <IndexListItemButton
      className={Inspection(inspection).htmlClass}
      onClick={() => {
        navigate(InspectorInspectionLink(inspection.id));
      }}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={4}>
          <Stack>
            <Typography>
              {inspection.name}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='place'/>: {inspection.place.name}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='inspection_subject'/>: {inspection.inspectionSubject.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <State state={inspection.state} />
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <Date date={inspection.dueDate} warning={inspection.incoming} error={inspection.overdue} />
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <Date date={inspection.doneAt} />
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <Result result={inspection.result} />
        </Grid>
      </Grid>
    </IndexListItemButton>
  );
};

export default ListItem;
