  import React from 'react';
  import { useQuery } from '@apollo/client';
  import { useParams } from 'react-router-dom';
  import { ContentContainer } from 'components/styles_components/container';
  import { FormattedMessage } from 'react-intl';
  import { InspectorInspectionsLink } from 'utils/link';
  import { useError } from 'contexts/error';
  import { BreadCrumbLink } from 'components/styles_components/link';
  import { BreadCrumbTypography } from 'components/styles_components/typography';
  import BreadcrumbContainer from 'components/breadcrumb_container';
  import ShowQuery from 'queries/inspection/inspector_show';
  import ShowItem from 'components/inspector_inspection/show_item';
  import Loader from 'components/loader';

  const Inspection = () => {
    const setError = useError();
    const { inspectionId } = useParams();
    const { data = { inspectorInspection: { } }, loading, refetch } = useQuery(
      ShowQuery,
      {
        variables: { id: inspectionId },
        onError: () => {
          setError('error.inspection.show');
        },
      }
    );

    if (loading) { return <Loader />; }

    return (
      <ContentContainer maxWidth='xl'>
        <BreadcrumbContainer>
          <BreadCrumbLink to={InspectorInspectionsLink()}>
            <FormattedMessage id='breadcrumb.inspector_inspections.index'/>
          </BreadCrumbLink>
          <BreadCrumbTypography>{data.inspectorInspection.name}</BreadCrumbTypography>
        </BreadcrumbContainer>
        <ShowItem inspection={data.inspectorInspection} refetch={refetch} />
      </ContentContainer>
    );
  };

  export default Inspection;
