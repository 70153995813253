import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WideFormGrid } from 'components/styles_components/form';
import { useQuery } from '@apollo/client';
import { useError } from 'contexts/error';
import Item from 'components/user/profile/modal/plan/item';
import Loader from 'components/loader';
import PlansQuery from 'queries/plan/index';

  const Index = ({ processing, onCancel, onSubmit }) => {
    const [selectedPlan, selectPlan] = useState();
    const setError = useError();
    const { data = { plans: { plans: [], user: { activePlan: {} } } }, loading} = useQuery(
      PlansQuery,
      {
        onError: () => {
          setError('error.plan.index');
        }
      }
    );

    if (loading) { return <Loader />; }

    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContent='center'>
        {data.plans.plans.map((plan) => (
          <Item plan={plan}
            key={plan.id}
            onClick={selectPlan}
            isSelected={plan == selectedPlan}
            isActive={plan.id === data.user.activePlan.id}/>
        ))}
        </Grid>
        <WideFormGrid
          container
          justifyContent='center'>
          <Button
            className='margin-top-10'
            fullWidth
            disabled={processing || !selectedPlan || selectedPlan.id === data.user.activePlan.id}
            color='primary'
            variant='contained'
            onClick={() => onSubmit({ variables: { id: selectedPlan.id }})}
            type='submit'>
              <FormattedMessage id='button.save'/>
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel'/>
            </Button>
          </Box>
        </WideFormGrid>
      </>
    );
  };

  export default Index;
