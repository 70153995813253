import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { PaginateProvider } from 'contexts/paginate';
import Index from 'components/space_users/index';
import IndexContainer from 'components/space_users/index_container';
import BreadcrumbContainer from 'components/breadcrumb_container';

const Users = () => {
  return (
    <PaginateProvider>
      <ContentContainer maxWidth='xl'>
        <BreadcrumbContainer>
          <BreadCrumbTypography><FormattedMessage id='breadcrumb.users.index'/></BreadCrumbTypography>
        </BreadcrumbContainer>
        <IndexContainer
          contentRenderer={(data) => (
            <Index spaceUsers={data.spaceUsers}/>
          )}/>
      </ContentContainer>
    </PaginateProvider>
  );
};

export default Users;
