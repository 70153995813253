import React from 'react';
import { FormattedMessage } from 'react-intl';
import ShowItemRow from 'components/item/show_item_row';

const PlanShow = ({ plan }) => {
  return <>
    {plan.name}
    <ShowItemRow label={<FormattedMessage id='plan.inspections_limit'/>}>
      {plan.inspectionsLimit}
    </ShowItemRow>
    <ShowItemRow label={<FormattedMessage id='plan.inspection_subjects_limit'/>}>
      {plan.inspectionSubjectsLimit}
    </ShowItemRow>
    <ShowItemRow label={<FormattedMessage id='plan.places_limit'/>}>
      {plan.placesLimit}
    </ShowItemRow>
  </>;
};

export default PlanShow;
