
import { gql } from '@apollo/client';

export default gql`
query inspectionSubjects($placeId: ID, $page: Int, $name: String, $serialNumber: String) {
  user {
    canAddInspectionSubject
  }
  inspectionSubjects(placeId: $placeId, page: $page, name: $name, serialNumber: $serialNumber) {
    perPage
    hasNextPage
    inspectionSubjectsTotalPages
    inspectionSubjectsTotal
    inspectionSubjects {
      id
      name
      serialNumber
      incomingInspectionsTotal
      overdueInspectionsTotal
      place {
        id
        name
      }
    }
  }
}
`;

