import React from 'react';
import { Grid, Stack, Typography, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InspectionSubjectLink } from 'utils/link';
import { IndexListItemButton } from 'components/styles_components/list';
import { FormattedMessage } from 'react-intl';
import InspectionSubject from 'utils/inspection_subject';

const ListItem = ({ inspectionSubject }) => {
  const navigate = useNavigate();
  const inspectionSubjectUtil = InspectionSubject(inspectionSubject);

  return (
    <IndexListItemButton
      className={inspectionSubjectUtil.htmlClass}
      onClick={() => {
        navigate(InspectionSubjectLink(inspectionSubject.id));
      }}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={6}>
          <Stack>
            <Typography>
              {inspectionSubject.name}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='place'/>: {inspectionSubject.place.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item textAlign='center' xs={2}>
          {inspectionSubject.serialNumber}
        </Grid>
        <Grid
          item
          textAlign='center'
          xs={2}>
          <Chip
            color={inspectionSubjectUtil.incomingInspectionsColor}
            label={inspectionSubject.incomingInspectionsTotal}/>
        </Grid>
        <Grid
          item
          textAlign='center'
          xs={2}>
          <Chip
            color={inspectionSubjectUtil.overdueInspectionsColor}
            label={inspectionSubject.overdueInspectionsTotal}/>
        </Grid>
      </Grid>
    </IndexListItemButton>
  );
};

export default ListItem;
