class InspectionState {
  PENDING = 0;
  DONE = 1;
  CANCELED = 2;

  constructor(inspectionState) {
    this.inspectionState = inspectionState;
  }

  get color() {
    switch (this.inspectionState) {
      case this.PENDING:
        return 'default';
      case this.DONE:
        return 'success';
      case this.CANCELED:
        return 'warning';
      default:
        return 'error';
    }
  }

  static create(inspection) {
    return new InspectionState(inspection);
  }
}

const InspectionStateFactory = (inspectionState) => new InspectionState(inspectionState);

export default InspectionStateFactory;
