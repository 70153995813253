import React from 'react';
import { Grid } from '@mui/material';
import { IndexListItemHeader } from 'components/styles_components/list';
import { FormattedMessage } from 'react-intl';

const ListHeader = ({ visible }) => {
  if (!visible) { return null; }

  return (
    <IndexListItemHeader>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={6} textAlign='left'>
          <FormattedMessage id='name'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection_subject.serial_number'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.incoming.count'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.overdue.count'/>
        </Grid>
      </Grid>
    </IndexListItemHeader>
  );
};

export default ListHeader;
