import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';

const createApolloClient = (graphQLServerUrl) => {
  console.log('API URL CAC: ', graphQLServerUrl);
  const httpLink = createHttpLink({
    uri: graphQLServerUrl,
    fetch: (uri, options) => {
      console.log(graphQLServerUrl, uri, options);
      options.credentials = 'include';
      return fetch(uri, options);
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message === 'Please authorize') {
          Cookies.remove('authenticated');
          window.location.reload();
        }
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return client;
};

export default createApolloClient;
