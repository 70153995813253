
import { gql } from '@apollo/client';
import InspectionFields from 'queries/inspection/inspection_fields_fragment';

export default gql`
  query inspections(
    $placeId: ID, $inspectionSubjectId: ID,
    $identifier: String, $sortBy: String,
    $name: String, $state: Int, $page: Int,
    $dueDateFrom: ISO8601DateTime, $dueDateTo: ISO8601DateTime,
    $doneAtFrom: ISO8601DateTime, $doneAtTo: ISO8601DateTime,
    $canceledAtFrom: ISO8601DateTime, $canceledAtTo: ISO8601DateTime,
    $notConditions: NotConditionsInput
    ) {
    user{
      canAddInspection
    }
    inspections(
      placeId: $placeId, inspectionSubjectId: $inspectionSubjectId,
      identifier: $identifier, sortBy: $sortBy,
      name: $name, state: $state, page: $page,
      dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo,
      doneAtFrom: $doneAtFrom, doneAtTo: $doneAtTo,
      canceledAtFrom: $canceledAtFrom, canceledAtTo: $canceledAtTo,
      notConditions: $notConditions
    ) {
      perPage
      hasNextPage
      inspectionsTotalPages
      inspectionsTotal
      inspections {
        ...InspectionFields
      }
    }
  }
  ${InspectionFields}
`;

