import React from 'react';
import { Grid, Stack, Typography, Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import Inspection from 'utils/inspection';
import InspectionState from 'utils/inspection_state';
import Date from 'components/date';
import State from 'components/inspection/state';
import Result from 'components/inspection/result';
import ShowItemRow from 'components/item/show_item_row';
import InspectionFieldsListIndex from 'components/inspection_field/index';
import Inspector from 'components/inspection/inspector';
import ExecuteButton from 'components/inspection/button/execute';
import GoogleLinkButton from 'components/navigation/google_link_button';

const ShowItem = ({ inspection, refetch }) => {
  const { place={}, inspectionSubject={} } = inspection;
  const inspectionStatUtil = InspectionState();

  return (
    <Stack>
      <WhiteCard className={Inspection(inspection).htmlClass}>
        <Typography variant='h6'><FormattedMessage id='inspection'/></Typography>
        <Grid container
          direction='row'
          justifyContent='space-between'
          spacing={2}
          alignItems='center'>
          <ShowItemRow label={<FormattedMessage id='name'/>}>
            {inspection.name}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.number'/>}>
            {inspection.number}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.state'/>}>
            <State
              state={inspection.state} />
          </ShowItemRow>
          <ShowItemRow
            label={<FormattedMessage id='inspection.due_date'/>}>
            <Date date={inspection.dueDate} warning={inspection.incoming} error={inspection.overdue} />
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspector'/>}>
            <Inspector inspector={inspection.inspector} />
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.period'/>}>
            {inspection.periodNumber} <FormattedMessage id={`inspection.period.${inspection.periodCycle}`}/>
          </ShowItemRow>
          {inspection.doneAt &&
            <>
              <ShowItemRow label={<FormattedMessage id='inspection.done_at'/>}>
                <Date date={inspection.doneAt}/>
              </ShowItemRow>
              <ShowItemRow label={<FormattedMessage id='inspection.result'/>}>
                <Result result={inspection.result}/>
              </ShowItemRow>
            </>
          }
          { inspection.state === inspectionStatUtil.CANCELED &&
            <ShowItemRow label={<FormattedMessage id='inspection.canceled_at'/>}>
              <Date date={inspection.canceledAt}/>
            </ShowItemRow>
          }
          <ShowItemRow label={<FormattedMessage id='inspection.notes'/>}>
            {inspection.notes}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.field.count'/>}>
            <InspectionFieldsListIndex
              showValues={inspection.state === inspectionStatUtil.DONE}
              inspectionFields={inspection.inspectionFields}/>
          </ShowItemRow>
        </Grid>
        <Grid item xs={12} lg={6}>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <ExecuteButton
              inspection={inspection}
              refetch={refetch} />
            </ButtonGroup>
          </Box>
        </Grid>
      </WhiteCard>
      <WhiteCard>
        <Typography variant='h6'><FormattedMessage id='inspection_subject'/></Typography>
        <ShowItemRow label={<FormattedMessage id='name'/>}>
          {inspectionSubject.name}
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection_subject.serial_number'/>}>
          {inspectionSubject.serialNumber || '-'}
        </ShowItemRow>
      </WhiteCard>
      <WhiteCard>
        <Typography variant='h6'><FormattedMessage id='place'/></Typography>
        <ShowItemRow label={<FormattedMessage id='name'/>}>{place.name}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.street'/>}>{place.street}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.zip_code'/>}>{place.zipCode}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.city'/>}>{place.city}</ShowItemRow>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <GoogleLinkButton
              place={place} />
          </ButtonGroup>
        </Box>
      </WhiteCard>
    </Stack>
  );
};

export default ShowItem;
