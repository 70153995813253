import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const PeriodSelect = (field) => {
  return (
    <FormControl
      fullWidth
      margin={field.margin}>
      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      <Select
        name={field.name}
        id={field.id}
        label={field.label}
        labelId={`${field.name}-label`}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        error={field.error}>
        <MenuItem value={'days'}><FormattedMessage id='inspection.period.days'/></MenuItem>
        <MenuItem value={'weeks'}><FormattedMessage id='inspection.period.weeks'/></MenuItem>
        <MenuItem value={'months'}><FormattedMessage id='inspection.period.months'/></MenuItem>
        <MenuItem value={'years'}><FormattedMessage id='inspection.period.years'/></MenuItem>
      </Select>
    </FormControl>
  );
};

export default PeriodSelect;
