import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import DragAndDropField from 'components/template_field/drag_and_drop_field';
import validationSchema from 'validation/template/create';

const Form = ({ onSubmit, onCancel, processing, initialValues }) => {
  const intl = useIntl();

  const handleSubmit = (values) => {
    values.templateFields = values.templateFields.map((field, index) => {
      field.order = index;
      return field;
    });
    onSubmit(values);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema(intl)}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {formik => {
        return (
          <WideForm onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin='dense'
              id='name'
              name='name'
              label={intl.formatMessage({ id: 'name' })}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) || undefined}
              helperText={formik.touched.name && formik.errors.name}/>
            <DragAndDropField
              name='templateFields'
              processing={processing}
              formik={formik}/>
            <Button
              fullWidth
              disabled={!formik.isValid || processing || !formik.dirty}
              color='primary'
              variant='contained'
              type='submit'>
              <FormattedMessage id='button.save' />
            </Button>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                size='small'
                disabled={processing}
                onClick={onCancel}>
                <FormattedMessage id='button.cancel' />
              </Button>
            </Box>
          </WideForm>
        );
      }}
    </Formik>
  );
};

export default Form;
