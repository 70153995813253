import React from 'react';
import { List } from '@mui/material';
import ListItem from 'components/inspection_subject/index/list_item';
import ListHeader from 'components/inspection_subject/index/list_header';

const Index = ({inspectionSubjects }) => {

  return (
    <List>
      <ListHeader visible={inspectionSubjects.inspectionSubjects.length> 0}/>
        {inspectionSubjects.inspectionSubjects.map((inspectionSubject) => (
        <ListItem key={inspectionSubject.id} inspectionSubject={inspectionSubject} />
      ))}
    </List>
  );
};

export default Index;
