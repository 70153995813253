import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, Box, Button } from '@mui/material';
import { Form as StyledForm } from 'components/styles_components/form';
import PlaceAutocomplete from 'components/form/autocomplete/place';
import validationSchema from 'validation/inspection_subject/create';

const Form = ({ onSubmit, onCancel, processing, initialValues }) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema(intl)}
      onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
        <StyledForm onSubmit={handleSubmit}>
          <PlaceAutocomplete
            margin='dense'
            id='placeId'
            name='placeId'
            label={intl.formatMessage({ id: 'place' })}
            value={values.placeId}
            onChange={handleChange}
            setValue={(value) => {
              handleChange({ target: { name: 'placeId', value } });
            }}
            onBlur={handleBlur}
            error={touched.placeId && Boolean(errors.placeId) || undefined}
            helperText={touched.placeId && errors.placeId}/>
          <TextField
            fullWidth
            margin='dense'
            id='name'
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name) || undefined}
            helperText={touched.name && errors.name}/>
          <TextField
            fullWidth
            margin='dense'
            id='serialNumber'
            name='serialNumber'
            label={intl.formatMessage({ id: 'inspection_subject.serial_number' })}
            value={values.serialNumber}
            onChange={handleChange}
            onBlur={handleBlur}/>
          <Button
            fullWidth
            className='margin-top-10'
            disabled={!isValid || processing || !dirty}
            color='primary'
            variant='contained'
            type='submit'>
            <FormattedMessage id='button.save'/>
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel'/>
            </Button>
          </Box>
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
