import React from 'react';
import { Grid, Typography, ButtonGroup, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { StyledLink } from 'components/styles_components/link';
import {  WhiteCard } from 'components/styles_components/card';
import { InspectionSubjectLink, PlaceLink, InspectionLink } from 'utils/link';
import Inspection from 'utils/inspection';
import InspectionState from 'utils/inspection_state';
import Date from 'components/date';
import State from 'components/inspection/state';
import Result from 'components/inspection/result';
import ShowItemRow from 'components/item/show_item_row';
import InspectionFieldsListIndex from 'components/inspection_field/index';
import Inspector from 'components/inspection/inspector';
import CancelButton from 'components/inspection/button/cancel';
import EditButton from 'components/inspection/button/edit';
import ExecuteButton from 'components/inspection/button/execute';
import DeleteButton from 'components/inspection/button/delete';
import GoogleLinkButton from 'components/navigation/google_link_button';

const ShowItem = ({ inspection, refetch }) => {
  const { place={}, inspectionSubject={} } = inspection;
  const inspectionStatUtil = InspectionState();

  return (
    <>
      <WhiteCard className={Inspection(inspection).htmlClass}>
      <Typography variant='h6'><FormattedMessage id='inspection'/></Typography>
        <Grid container
          direction='row'
          justifyContent='space-between'
          spacing={2}>
          <ShowItemRow label={<FormattedMessage id='name'/>}>
            {inspection.name}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.number'/>}>
            {inspection.number}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.state'/>}>
            <State
              state={inspection.state} />
          </ShowItemRow>
          <ShowItemRow
            label={<FormattedMessage id='inspection.due_date'/>}>
            <Date date={inspection.dueDate} warning={inspection.incoming} error={inspection.overdue} />
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspector'/>}>
            <Inspector inspector={inspection.inspector} ownerRole={inspection.ownerRole} />
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.period'/>}>
            {inspection.periodNumber} <FormattedMessage id={`inspection.period.${inspection.periodCycle}`}/>
          </ShowItemRow>
          {inspection.doneAt &&
            <>
              <ShowItemRow label={<FormattedMessage id='inspection.done_at'/>}>
                <Date date={inspection.doneAt}/>
              </ShowItemRow>
              <ShowItemRow label={<FormattedMessage id='inspection.result'/>}>
                <Result result={inspection.result}/>
              </ShowItemRow>
            </>
          }
          { inspection.state === inspectionStatUtil.CANCELED &&
            <ShowItemRow label={<FormattedMessage id='inspection.canceled_at'/>}>
              <Date date={inspection.canceledAt}/>
            </ShowItemRow>
          }
          { inspection.previousInspection &&
            <ShowItemRow label={<FormattedMessage id='inspection.previous'/>}>
              <StyledLink to={InspectionLink(inspection.previousInspection.id)}>
                {inspection.previousInspection.name}
              </StyledLink>
            </ShowItemRow>
          }
          { inspection.nextInspection &&
            <ShowItemRow label={<FormattedMessage id='inspection.next'/>}>
              <StyledLink to={InspectionLink(inspection.nextInspection.id)}>
                {inspection.nextInspection.name}
              </StyledLink>
            </ShowItemRow>
          }
          <ShowItemRow label={<FormattedMessage id='inspection.notes'/>}>
            {inspection.notes}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.field.count'/>}>
            <InspectionFieldsListIndex
              showValues={inspection.state === inspectionStatUtil.DONE}
              inspectionFields={inspection.inspectionFields}/>
          </ShowItemRow>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            display='flex'
            justifyContent='flex-end'>
            <ButtonGroup variant='contained'>
              <ExecuteButton
                inspection={inspection}
                refetch={refetch} />
              <CancelButton
                inspection={inspection}
                refetch={refetch} />
              <EditButton
                inspection={inspection}
                refetch={refetch} />
              <GoogleLinkButton
                place={place} />
              <DeleteButton
                inspection={inspection} />
            </ButtonGroup>
          </Box>
        </Grid>
      </WhiteCard>

      <WhiteCard>
        <Typography variant='h6'><FormattedMessage id='inspection_subject'/></Typography>
        <ShowItemRow label={<FormattedMessage id='inspection_subject'/>}>
          <StyledLink to={InspectionSubjectLink(inspectionSubject.id)}>
            {inspectionSubject.name}
          </StyledLink>
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection_subject.serial_number'/>}>{inspectionSubject.serialNumber}</ShowItemRow>
      </WhiteCard>

      <WhiteCard>
        <Typography variant='h6'><FormattedMessage id='place'/></Typography>
        <ShowItemRow label={<FormattedMessage id='place'/>}>
          <StyledLink to={PlaceLink(place.id)}>
            {place.name}
          </StyledLink>
        </ShowItemRow>

        <ShowItemRow label={<FormattedMessage id='place.street'/>}>{place.street}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.zip_code'/>}>{place.zipCode}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.city'/>}>{place.city}</ShowItemRow>
        <Box
            display='flex'
            justifyContent='flex-end'>
            <ButtonGroup variant='contained'>
              <GoogleLinkButton
                place={place} />
            </ButtonGroup>
          </Box>
      </WhiteCard>
    </>
  );
};

export default ShowItem;
