import * as yup from 'yup';

const validationSchema = (intl) => yup.object().shape({
  email: yup
    .string('')
    .email(intl.formatMessage({ id: 'validation.email' }))
    .required(intl.formatMessage({ id: 'validation.required' })),
  password: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
});

export default validationSchema;
