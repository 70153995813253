import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { TemplatesLink } from 'utils/link';
import { useError } from 'contexts/error';
import { BreadCrumbLink } from 'components/styles_components/link';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { ContentContainer } from 'components/styles_components/container';
import TemplateQuery from 'queries/template/show';
import ShowItem from 'components/template/show_item';
import Loader from 'components/loader';
import BreadcrumbContainer from 'components/breadcrumb_container';

const Template = () => {
  const { id } = useParams();
  const setError = useError();
  const { data = { template: { adminRole: true, templateFields: [] } }, loading, refetch } = useQuery(
    TemplateQuery,
    {
      variables: { id },
      onCompleted: (data) => {
        if (!data.template) {
          setError('error.template.show');
        }
      },
      onError: () => {
        setError('error.template.show');
      }
    }
  );
  if (loading) { return <Loader />; }

  if (!data.template) return null;

  return (
    <ContentContainer maxWidth='xl'>
      <BreadcrumbContainer>
        <BreadCrumbLink to={TemplatesLink()}>
          <FormattedMessage id='breadcrumb.template.index'/>
        </BreadCrumbLink>
        <BreadCrumbTypography>{data.template.name}</BreadCrumbTypography>
      </BreadcrumbContainer>
      <ShowItem template={data.template} refetch={refetch} />
    </ContentContainer>
  );
};

export default Template;
