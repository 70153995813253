import React, { useState } from 'react';
import { Button, Pagination, Box, Drawer, ButtonGroup } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { FilterProvider, useFilter, initInspectionFilterValues } from 'contexts/filter';
import { usePaginate } from 'contexts/paginate';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import FilterForm from 'components/inspection/filter_form';
import FilterTags from 'components/filter/tags';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Loader from 'components/loader';
import CreateButton from 'components/inspection/button/create';
import EmptyInfo from 'components/empty_info';
import CheckInfo from 'components/user/plan/check_info';
import InspectionQuery from 'queries/inspection/index';
import Inspections from 'components/inspection/index';

const IndexContainer = ({ placeId, inspectionSubjectId }) => {
  const { page, onPageChange } = usePaginate();
  const { inspectionsFilter, setInspectionsFilter } = useFilter();
  const [filterVisible, setFilterVisible] = useState(false);
  const setError = useError();
  const { data = { user: { canAddInspection: true },  inspections: { inspections: []} }, loading } = useQuery(
    InspectionQuery,
    {
      variables: {
        ...inspectionsFilter,
        page: page,
        placeId: placeId,
        inspectionSubjectId: inspectionSubjectId,
        state: inspectionsFilter.state.length === 0 ? null : inspectionsFilter.state,
      },
      onError: () => {
        setError('error.inspection.index');
      }
    }
  );
  const { canAddInspection } = data.user;

  return (
    <FilterProvider>
      {filterVisible &&
        <Drawer
          anchor='right'
          open={filterVisible}
          onClose={() => { setFilterVisible(false);} }>
          <FilterForm
            initialValues={inspectionsFilter}
            defaultValues={initInspectionFilterValues}
            onClose={() => { setFilterVisible(false);} }
            onSubmit={(values) => {
              onPageChange(1);
              setFilterVisible(false);
              setInspectionsFilter(values);
            }}/>
        </Drawer>
      }
      {loading
        ? <Loader/>
        : <>
          <CheckInfo visible={!canAddInspection} />
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <CreateButton
                placeId={placeId}
                inspectionSubjectId={inspectionSubjectId}
                disabled={!canAddInspection}/>
              <Button
                onClick={() => setFilterVisible(!filterVisible)}
                variant='contained'
                color={JSON.stringify(initInspectionFilterValues) === JSON.stringify(inspectionsFilter) ? 'primary' : 'info'}>
                <FormattedMessage id='button.filter'/> <FilterAltIcon/>
              </Button>
            </ButtonGroup>
          </Box>

          <FilterTags
            filterValues={inspectionsFilter}
            defaultValues={initInspectionFilterValues}
            onFilterChange={(newFilterValues) => {
              onPageChange(1);
              setInspectionsFilter(newFilterValues);
            }}/>
          <EmptyInfo
            visible={data.inspections.inspections.length === 0}
            description={<FormattedMessage id='empty.inspections.title.short'/>}/>
          <Inspections inspections={data.inspections.inspections} />
          <Box display='flex' justifyContent='center'>
          { data.inspections.inspectionsTotalPages> 1 &&
            <Pagination
              count={data.inspections.inspectionsTotalPages}
              page={page}
              onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
          }
          </Box>
        </>
      }
      <DividerContainer/>
    </FilterProvider>
  );
};

export default IndexContainer;
