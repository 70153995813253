
import { gql } from '@apollo/client';

export default gql`
  query inspectorInspections(
    $identifier: String, $sortBy: String,
    $name: String, $state: Int, $page: Int,
    $dueDateFrom: ISO8601DateTime, $dueDateTo: ISO8601DateTime,
    $doneAtFrom: ISO8601DateTime, $doneAtTo: ISO8601DateTime,
    $canceledAtFrom: ISO8601DateTime, $canceledAtTo: ISO8601DateTime,
    $notConditions: NotConditionsInput
    ) {
    inspectorInspections(
      identifier: $identifier, sortBy: $sortBy,
      name: $name, state: $state, page: $page,
      dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo,
      doneAtFrom: $doneAtFrom, doneAtTo: $doneAtTo,
      canceledAtFrom: $canceledAtFrom, canceledAtTo: $canceledAtTo,
      notConditions: $notConditions
    ) {
      perPage
      hasNextPage
      inspectionsTotalPages
      inspectionsTotal
      inspections {
        id
        identifier
        dueDate
        doneAt
        canceledAt
        periodCycle
        periodNumber
        name
        number
        state
        result
        overdue
        incoming
        place {
          name
          street
          city
          adminRole
          zipCode
        }
        inspectionSubject {
          name
          serialNumber
        }
      }
    }
  }
`;

