
import { gql } from '@apollo/client';

export default gql`
  query spaceUsers($page: Int, $name: String, $email: String) {
    spaceUsers(page: $page, name: $name, email: $email) {
      hasNextPage
      spaceUsersTotalPages
      currentPage
      spaceUsers {
        id
        email
        name
        surname
        confirmed
      }
    }
  }
`;

