
import { gql } from '@apollo/client';

export default gql`
  query userDashboard {
    user {
      email
      canAddPlace
      canAddInspection
      canAddInspectionSubject
      userSubscription {
        id
        startDate
        endDate
        plan {
          id
          name
          price
          billingInterval
          placesLimit
          inspectionSubjectsLimit
          inspectionsLimit
        }
      }
      incomingInspectionsTotal
      overdueInspectionsTotal
      inspectionsTotal
      inspectionSubjectsTotal
      placesTotal

      visibleInspectionSubjectsTotal
      visibleInspectionsTotal
      visibleIncomingInspectionsTotal
      visibleOverdueInspectionsTotal
      visiblePlacesTotal

      inspectorInspectionsTotal
      inspectorIncomingInspectionsTotal
      inspectorOverdueInspectionsTotal
    }
    places {
      placesTotal
    }
    inspectionSubjects {
      inspectionSubjectsTotal
    }
    inspections {
      inspectionsTotal
    }
  }
`;

