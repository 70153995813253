import React from 'react';
import { Button, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import DragAndDropItem from 'components/drag_and_drop/item';

const Item = ({ id, index, processing, obj, arrayHelpers, setFieldValue, moveCard, errors }) => {
  const intl = useIntl();
  const error = errors?.templateFields?.[index]?.name;

  return (
    <DragAndDropItem
      id={id}
      index={index}
      arrayHelpers={arrayHelpers}
      moveCard={moveCard}>
      <TextField
        label={intl.formatMessage({ id: 'inspection.field.name' })}
        name={`templateFields[${index}].name`}
        type='text'
        disabled={processing}
        value={obj.name}
        error={error}
        helperText={error}
        onChange={(e) => {
          setFieldValue(`templateFields[${index}].name`, e.target.value);
        }}/>
      <Button
        onClick={() => {
          arrayHelpers.remove(index);
        }}
        variant='text'
        size='small'
        disabled={processing}>
        <FormattedMessage id='button.delete'/>
      </Button>
    </DragAndDropItem>
  );
};

export default Item;
