import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { MenuList, ListItemText, Box, Drawer, Divider } from '@mui/material';
import { SideMenuItem } from 'components/styles_components/menu';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from 'components/styles_components/link';
import { DividerContainer } from 'components/styles_components/container';
import { HomeLink, InspectionsLink, InspectionSubjectsLink, InspectorInspectionsLink, PlacesLink, TemplatesLink, UsersLink } from 'utils/link';
import Logo from 'components/layout/logo';

const SideMenu = ({visible}) => {
  const location = useLocation();
  const theme = useTheme();

  const item = (link, translationId) => {
    return (
      <ButtonLink to={link}>
        <SideMenuItem
          selected={location.pathname === link}>
          <ListItemText><FormattedMessage id={translationId}/></ListItemText>
        </SideMenuItem>
      </ButtonLink>
    );
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: theme.width.sideMenu },
      }}
      variant='persistent'
      open={visible}>
      <MenuList>
      <ButtonLink to={HomeLink()}>

          <Logo className='menu' width={80}  />
          </ButtonLink>

        <Divider><FormattedMessage id='inspector' /></Divider>
        {item(InspectorInspectionsLink(), 'navbar.inspector_inspections')}
        <DividerContainer/>
        <Divider><FormattedMessage id='admin' /></Divider>
        {item(PlacesLink(), 'navbar.places')}
        {item(InspectionSubjectsLink(), 'navbar.inspection_subjects')}
        {item(InspectionsLink(), 'navbar.inspections')}
        {item(TemplatesLink(), 'navbar.templates')}
        {item(UsersLink(), 'navbar.users')}
      </MenuList>
    </Drawer>
  );
};

export default SideMenu;

