import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { useInfo } from 'contexts/info';
import { useError } from 'contexts/error';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteMutation from 'mutations/place/permission/delete';
import Confimation from 'components/form/confirmation';

const DeleteButton = ({ placeId, spaceUserId, onSuccess }) => {
  const setInfo = useInfo();
  const setError = useError();
  const intl = useIntl();

  const [deleteMutation, deleteMutationData] = useMutation(DeleteMutation, {
    onCompleted: () => {
      setInfo(intl.formatMessage({ id: 'info.user.deleted' }));
      onSuccess();
    },
    onError: () => {
      setError('error.user.delete');
    },
  });

  return (
    <Confimation
      title={<FormattedMessage id='header.user.delete'/>}
      description={<FormattedMessage id='confirmation.user.delete'/>}
      onClick={() => {
        deleteMutation({ variables: { id: placeId, spaceUserId: spaceUserId }});
      }}>
      <Button
        color='error'
        variant='contained'
        size='small'
        disabled={deleteMutationData.loading}>
        <FormattedMessage id='button.delete'/>
      </Button>
    </Confimation>
  );
};

export default DeleteButton;
