import React from 'react';
import { useError } from 'contexts/error';
import { useInfo } from 'contexts/info';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { HomeLink } from 'utils/link';
import ConfirmMutation from 'mutations/space/confirm_invitation';
import Loader from 'components/loader';

const InvitationConfimation = () => {
  const { token } = useParams();
  const setInfo = useInfo();
  const setError = useError();
  const navigate = useNavigate();
  const intl = useIntl();

  const [confirmMutation, { loading }] = useMutation(ConfirmMutation, {
    onCompleted: ({ success }) => {
      if (!success) {
        setError('error.user.space.confirm');
      } else {
        setInfo(intl.formatMessage({ id: 'info.user.space.confirmed' }));
      }
      navigate(HomeLink());
    },
    onError: () => {
      setError('error.user.space.confirm');
    },
  });

  confirmMutation({variables: { token }});

  if (loading) { return <Loader />; }

  return null;
};

export default InvitationConfimation;
