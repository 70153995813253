import React from 'react';
import { useMutation } from '@apollo/client';
import { ModalTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';
import { useError } from 'contexts/error';
import CreateMutation from 'mutations/space/invite_user';
import Form from 'components/space_users/form';

const Create = ({ handleClose, onSuccess }) => {
  const setError = useError();
  const [createMutation, { loading }] = useMutation(CreateMutation, {
    onCompleted: ({ inviteSpaceUser }) => {
      onSuccess(inviteSpaceUser.spaceUser);
    },
    onError: () => {
      setError('error.user.create');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.user.create'/>
      </ModalTypography>
      <Form
        initialValues={{
          email: '',
        }}
        processing={loading}
        onSubmit={(values) => createMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Create;
