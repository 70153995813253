import '@fontsource/roboto';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({

  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    fontSize: 18,
    fontSizeSmall: 16,
    lineHeight: 1.6,
  },
  palette: {
    primary: {
      main: '#3D606F',
      white: '#FFFFFF'
    },
    secondary: {
      main: '#8E8F85',
    },
    info: {
      main: '#f3f3f3'
    },
    background: {
      default: '#F9F8F9',
      secondary: ' #ebebeb',
    },
    columnBackground: {
      main: '#F9F8F9',
      hover: '#ebebeb'
    },
    link: {
      main: '#3D606F',
      hover: '#98B9D3'
    },
  },
  padding: {
    small: '5px',
    content: '10px',
    container: '20px',
    containerBig: '50px',
    section: '20px'

  },
  radius: {
    card: '12px'
  },
  height: {
    topMenu: '64px',
    footer: '64px'
  },
  width: {
    sideMenu: '300px',
    filterDrawer: '450px',
    form: '700px'
  },
  shape: {
    borderRadius: 12, // Default border radius for all components
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: '8px', // Custom border radius for buttons
  //       },
  //     },
  //   },
  // },
});

export default theme;
