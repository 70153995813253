
import { gql } from '@apollo/client';

export default gql`
  fragment InspectionFields on Inspection {
    id
    identifier
    dueDate
    doneAt
    canceledAt
    periodCycle
    periodNumber
    name
    number
    state
    result
    overdue
    incoming
    place {
      id
      name
      street
      city
      adminRole
      zipCode
    }
    inspectionSubject {
      id
      name
      serialNumber
      place {
        id
      }
    }
  }
`;

