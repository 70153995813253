import React from 'react';
import { Formik, FieldArray } from 'formik';
import { Grid, TextField, Box, Button, List } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePicker from 'components/form/date_picker';
import ResultSelect from 'components/form/result_select';
import InspectionFieldExecuteItem from 'components/inspection_field/execute_item';
import validationSchema from 'validation/inspection/execute';
import { FormHintTypography } from 'components/styles_components/typography';

const ExecuteForm = ({ initialValues, processing, onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}>
      {formik => (
        <WideForm onSubmit={formik.handleSubmit}>
          <DatePicker
            className='width-100 margin-top-10'
            name='doneAt'
            id='doneAt'
            label={intl.formatMessage({ id: 'inspection.done_at' })}
            value={formik.values.doneAt}
            onChange={(value) => formik.setFieldValue('doneAt', value)} />
          <DatePicker
            className='width-100 margin-top-10'
            name='dueDate'
            id='dueDate'
            label={intl.formatMessage({ id: 'form.inspection.due_date_for_next_inspection' })}
            value={formik.values.dueDate}
            onChange={(value) => formik.setFieldValue('dueDate', value)} />
          <FormHintTypography>
            <FormattedMessage id='form.inspection.due_date_for_next_inspection.hint' />
          </FormHintTypography>
          <Grid className='margin-top-10'>
            <ResultSelect
              className='width-100'
              name='result'
              id='result'
              label={intl.formatMessage({ id: 'inspection.result' })}
              value={formik.values.result}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.result && Boolean(formik.errors.result) || undefined}
              helperText={formik.touched.result && formik.errors.result} />
          </Grid>
          <FieldArray
            name='inspectionFields'
            validateOnChange={false}
            render={() => (
              <List>
                {formik.values.inspectionFields.map((obj, index) => (
                  <InspectionFieldExecuteItem
                    key={index}
                    index={index}
                    obj={obj}
                    processing={processing}
                    setFieldValue={formik.setFieldValue} />
                ))}
              </List>
            )} />
          <TextField
            fullWidth
            multiline
            margin='dense'
            type='notes'
            id='notes'
            name='notes'
            label={intl.formatMessage({ id: 'inspection.notes' })}
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.notes && Boolean(formik.errors.notes) || undefined}
            helperText={formik.touched.notes && formik.errors.notes} />
          <Button
            fullWidth
            className='margin-top-10'
            disabled={!formik.isValid || processing}
            color='primary'
            variant='contained'
            type='submit'>
            <FormattedMessage id='button.save' />
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              size='small'
              variant='text'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel' />
            </Button>
          </Box>
        </WideForm>
      )}
    </Formik>
  );
};

export default ExecuteForm;
