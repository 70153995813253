import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import BaseEmptyInfo from 'components/empty_info';
import CreateInspectionButton from 'components/inspection/button/create';

const EmptyInfo = ({ visible, placeId, inspectionSubjectId }) => {
  if (!visible) { return null; }

  if (visible) {
    return (
      <WhiteCard>
      <BaseEmptyInfo
        visible={true}
        description={<FormattedMessage id='empty.inspections.title'/>}>
        <CreateInspectionButton
          placeId={placeId}
          inspectionSubjectId={inspectionSubjectId} />
      </BaseEmptyInfo>
      </WhiteCard>
    );
  } else {
    return null;
  }
};

export default EmptyInfo;
