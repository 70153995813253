import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InfoAlert } from 'components/styles_components/alert';

const CheckInfo = ({ visible }) => {
  const [open, setOpen] = useState(true);

  if (!visible || !open) { return null; }

  return (
    <InfoAlert
      severity='warning'
      action={
        <IconButton
          size='small'
          onClick={() => {
            setOpen(false);
          }}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    }>
      <FormattedMessage id='plan.check_info'/>
    </InfoAlert>
  );
};

export default CheckInfo;
