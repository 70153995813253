import React from 'react';
import { Grid, Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import ShowItemRow from 'components/item/show_item_row';
import Boolean from 'components/boolean';
import DeleteButton from 'components/space_users/button/delete';


const ShowItem = ({ spaceUser }) => {
  return (
    <WhiteCard>
      <Grid container
        direction='row'
        justifyContent='space-between'
        spacing={2}
        alignItems='center'>
        <ShowItemRow label={<FormattedMessage id='user.email'/>}>
          {spaceUser.email}
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='user.confirmed'/>}>
          <Boolean value={spaceUser.confirmed}/>
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='user.firstname_and_surname'/>}>
          {spaceUser.name} {spaceUser.surname}
        </ShowItemRow>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <DeleteButton
              spaceUser={spaceUser} />
          </ButtonGroup>
        </Box>
      </Grid>
    </WhiteCard>
  );
};

export default ShowItem;
