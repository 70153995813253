import React from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Form from 'components/filter/from';

const FilterForm = ({initialValues, defaultValues, processing, onSubmit, onClose }) => {
  const intl = useIntl();

  return (
    <Form
      header={<FormattedMessage id='header.place.filter'/>}
      processing={processing}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      contentRenderer={(formik) => (
        <>
          <TextField
            fullWidth
            autoFocus
            margin='dense'
            id='name'
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}/>
        </>
    )} />
  );
};

export default FilterForm;
