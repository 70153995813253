import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import Create from 'components/assigned_users/modal/create';
import AddIcon from '@mui/icons-material/Add';

const CreateButton = ({ placeId, onSuccess }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button
      onClick={() => addModal(
        <Create
          placeId={placeId}
          handleClose={() => removeModal()}
          onSuccess={() => {
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.user.created' }));
            onSuccess();
          }} />
      )}
      variant='contained'
      color='success'>
      <FormattedMessage id='button.add.user'/> <AddIcon/>
    </Button>
  );
};

export default CreateButton;
