import React from 'react';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from 'utils/apollo_client';

const Apollo = ({apiUrl, children}) => {
  console.log("API URL: ", apiUrl);
  return (
    <ApolloProvider client={createApolloClient(apiUrl)}>
      {children}
    </ApolloProvider>
  );
};

export default Apollo;
