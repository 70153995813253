export const InspectionLink = (inspectionId, tab=0) => {
  return `/inspections/${inspectionId}?tab=${tab}`;
};

export const InspectionSubjectLink = (inspectionSubjectId, tab=0) => {
  return `/inspection_subjects/${inspectionSubjectId}?tab=${tab}`;
};

export const PlaceLink = (placeId, tab=0) => {
  return `/places/${placeId}?tab=${tab}`;
};

export const PlacesLink = () => {
  return '/places';
};

export const InspectionsLink = () => {
  return '/inspections';
};

export const InspectionSubjectsLink = () => {
  return '/inspection_subjects';
};

export const HomeLink = () => {
  return '/';
};

export const ProfileLink = () => {
  return '/users/profile';
};

export const LoginLink = () => {
  return '/users/login';
};

export const RegisterLink = () => {
  return '/users/register';
};

export const ForgotPasswordLink = () => {
  return '/users/forgot_password';
};

export const TemplatesLink = () => {
  return '/templates';
};

export const TemplateLink = (template_id) => {
  return `/templates/${template_id}`;
};

export const GoogleLink = (place) => {
  return `https://www.google.com/maps?q=${place.street}+${place.zipCode}+${place.city}`;
};

export const UsersLink = () => {
  return '/users';
};

export const UserLink = (userId) => {
  return `/users/${userId}`;
};

export const InspectorInspectionsLink = () => {
  return '/inspector_inspections';
};

export const InspectorInspectionLink = (inspectionId) => {
  return `/inspector_inspections/${inspectionId}`;
};
