import { gql } from '@apollo/client';

export default gql`
  mutation createInspectionSubject($name: String!, $serialNumber: String, $placeId: ID!) {
    createInspectionSubject(input: {
      name: $name, serialNumber: $serialNumber, placeId: $placeId
    }) {
      inspectionSubject {
        id
        name
        serialNumber
        place {
          id
        }
      }
    }
  }
`;
