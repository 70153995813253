import React, { useState, useEffect, useRef } from 'react';
import { useModal } from 'contexts/modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { Grid, Button } from '@mui/material';
import { useError } from 'contexts/error';
import AddIcon from '@mui/icons-material/Add';
import InspectionSubjectsQuery from 'queries/inspection_subject/index_with_place';
import InspectionSubjectQuery from 'queries/inspection_subject/show';
import OptionableAutocomplete from 'components/form/autocomplete/optionable';
import CreateInspectionSubject from 'components/inspection_subject/modal/create';

const InspectionSubject = ({ placeId, id, label, name, onChange, onBlur, value, error, helperText, setValue }) => {
  const setError = useError();
  const intl = useIntl();
  const {addModal, removeModal} = useModal();
  const prevPlaceId = useRef(placeId);
  const [searchValue, setSearchValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  const [loadInspectionSubjects, { data = { place: { canAddInspectionSubject: true }, inspectionSubjects: { inspectionSubjects: [], inspectionSubjectsTotal: 0 } }, loading }] = useLazyQuery(
    InspectionSubjectsQuery, {
      variables: { page: 1, placeId: parseInt(placeId), name: searchValue },
      onError: () => {
        setError('error.inspection_subject.index');
      }
    }
  );

  const [loadDefaultInspectionSubject, defaultInspectionSubjectData] = useLazyQuery(
    InspectionSubjectQuery, {
      variables: { id: value },
      onCompleted: (data) => {
        setDefaultValue({ label: data.inspectionSubject.name, value: data.inspectionSubject.id });
      },
      onError: () => {
        setError('error.inspection_subject.show');
      }
    }
  );

  const options = data.inspectionSubjects.inspectionSubjects.map(inspectionSubject => ({ label: inspectionSubject.name, value: inspectionSubject.id }));

  useEffect(() => {
    if (value) {
      loadDefaultInspectionSubject();
    }
  }, [loadDefaultInspectionSubject, value]);

  useEffect(() => {
    if (placeId) {
      loadInspectionSubjects();
    }
  }, [loadInspectionSubjects, placeId, searchValue]);

  useEffect(() => {
    if (prevPlaceId.current !== placeId) {
      if (defaultValue) {
        setDefaultValue(null);
        setValue(name, null);
      }
      prevPlaceId.current = placeId;
    }
  }, [defaultValue, name, placeId, setValue]);


  const onChangeInternal = (evt, value) => {
    setDefaultValue(value);
    onChange(evt, value);
  };

  const addButton = () => {
    if (!data.place.canAddInspectionSubject) return null;

    return (
      <Button
        onClick={() => addModal(
          <CreateInspectionSubject
          handleClose={() => removeModal()}
          placeId={placeId}
          name={searchValue}
          onSuccess={(inspectionSubject) => {
            setDefaultValue({ label: inspectionSubject.name, value: inspectionSubject.id });
            removeModal();
            setValue(name, inspectionSubject.id);
          }}/>
        )}
        variant='contained'
        color='success'>
        <AddIcon/>
      </Button>
    );
  };

  return (
    <>
      <OptionableAutocomplete
        disabled={!placeId}
        id={id}
        label={label}
        loadingText={intl.formatMessage({ id: 'autocomplete.inspection_subject.loading' })}
        noOptionsText={
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <FormattedMessage id='autocomplete.inspection_subject.no_options'/>
            {addButton()}
          </Grid>
        }
        options={options}
        name={name}
        onChange={onChangeInternal}
        value={defaultValue}
        onBlur={onBlur}
        loading={loading || defaultInspectionSubjectData.loading}
        error={error}
        helperText={helperText}
        onSearch={setSearchValue}/>
    </>
  );
};

export default InspectionSubject;
