import React from 'react';
import { Grid } from '@mui/material';
import { IndexListItemHeader } from 'components/styles_components/list';
import { FormattedMessage } from 'react-intl';

const ListHeader = ({ visible }) => {
  if (!visible) { return null; }

  return (
    <IndexListItemHeader>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={4} textAlign='left'>
          <FormattedMessage id='name'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.state'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.due_date'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.done_at'/>
        </Grid>
        <Grid item xs={2} textAlign='center'>
          <FormattedMessage id='inspection.result'/>
        </Grid>
      </Grid>
    </IndexListItemHeader>
  );
};

export default ListHeader;
