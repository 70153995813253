import React from 'react';
import { Box } from '@mui/material';
import { useError } from 'contexts/error';
import { useQuery } from '@apollo/client';
import { ContentContainer, SectionGrid } from 'components/styles_components/container';
import DashboardQuery from 'queries/user/dashboard';
import Loader from 'components/loader';
import CheckInfo from 'components/user/plan/check_info';
import CreateButton from 'components/inspection/button/create';
import PlaceWizardEmptyInfo from 'components/place/wizard/empty_info';
import InspectionSubjectsWizardEmptyInfo from 'components/inspection_subject/wizard/empty_info';
import InspectionsWizardEmptyInfo from 'components/inspection/wizard/empty_info';
import Calendar from 'components/dashboard/calendar';

// Check simple index
const Place = () => {
  const setError = useError();

  const { data = { user: { }, inspections: {}, places: {}, inspectionSubjects: {} }, loading } = useQuery(
    DashboardQuery,
    {
      onError: () => {
        setError('error.user.dashboard');
      }
    }
  );

  if (loading) { return <Loader />; }

  return (
    <ContentContainer maxWidth='xl'>
      <CheckInfo visible={!data.user.canAddInspection} />
      {!loading && data.inspections.inspectionsTotal > 0 &&
        <Box
          display='flex'
          justifyContent='flex-end'>
          <CreateButton
            disabled={!data.user.canAddInspection}/>
        </Box>
      }

      <SectionGrid>
        <PlaceWizardEmptyInfo
          visible={!loading && data.places.placesTotal === 0 } />
        <InspectionSubjectsWizardEmptyInfo
          visible={!loading && data.places.placesTotal > 0 && data.inspectionSubjects.inspectionSubjectsTotal === 0} />
        <InspectionsWizardEmptyInfo
          visible={!loading && data.inspectionSubjects.inspectionSubjectsTotal > 0 && data.inspections.inspectionsTotal == 0} />
      </SectionGrid>

      <Calendar />
    </ContentContainer>
  );
};

export default Place;
