import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const StateSelect = (field) => {
  return (
    <FormControl
      fullWidth
      margin={field.margin}>
      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      <Select
        name={field.name}
        id={field.id}
        label={field.label}
        labelId={`${field.name}-label`}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        error={field.error}>
      <MenuItem value={''}>-</MenuItem>
      <MenuItem value={0}><FormattedMessage id='inspection.state.0'/></MenuItem>
      <MenuItem value={1}><FormattedMessage id='inspection.state.1'/></MenuItem>
      <MenuItem value={2}><FormattedMessage id='inspection.state.2'/></MenuItem>
      </Select>
    </FormControl>
  );
};

export default StateSelect;
