import styled from 'styled-components';
import theme from 'theme';

export const LabelSpan = styled.span`
  color: ${theme.palette.secondary.main};
  min-width: 300px;
  display: inline-block;
`;
export const ContentSpan = styled.span`
  text-align: right;
`;

export const InlineSpan = styled.span`
  display: inline-flex;
`;
