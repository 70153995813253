
import * as yup from 'yup';

const validationSchema = (intl) => yup.object({
  name: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  street: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  zipCode: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  city: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
});

export default validationSchema;
