import { gql } from '@apollo/client';

export default gql`
  mutation resetPassword($email: String!, $password: String!, $token: String!, $deviceToken: String, $deviceType: String) {
    resetPassword(input: { email: $email, password: $password, token: $token, deviceToken: $deviceToken, deviceType: $deviceType }) {
      user {
        id
        email
      }
      expiresIn
    }
  }
`;
