import { gql } from '@apollo/client';

export default gql`
  mutation createPlace($name: String!, $street: String!, $city: String!, $zipCode: String!) {
    createPlace(input: { name: $name, street: $street, city: $city, zipCode: $zipCode }) {
      place {
        id
        name
      },
    }
  }
`;
