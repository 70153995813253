import React from 'react';
import { ButtonGroup, Box } from '@mui/material';
import { useError } from 'contexts/error';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { WhiteCard } from 'components/styles_components/card';
import { MainTypography } from 'components/styles_components/typography';
import ProfileQuery from 'queries/user/profile';
import ShowItemRow from 'components/item/show_item_row';
import Loader from 'components/loader';
import Boolean from 'components/boolean';
import SubscriptionShow from 'components/user/subscrption/show';
import EditButton from 'components/user/profile/button/edit';
import PlanShow from 'components/user/plan/show';
import PlanSelectButton from 'components/user/profile/button/plan_select';
import PasswordChangeButton from 'components/user/profile/button/password_change';

const Show = () => {
  const setError = useError();
  const { data = { user: { }}, loading, refetch } = useQuery(
    ProfileQuery,
    {
      onError: () => {
        setError('error.user.profile.show');
      }
    }
  );

  return (
    <>
      {loading
      ? <Loader/>
      : <>
        <WhiteCard>
          <ShowItemRow label={<FormattedMessage id='user.firstname'/>}>
            {data.user.name}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='user.surname'/>}>
            {data.user.surname}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='user.email'/>}>
            {data.user.email}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='settings.send_email_notifications'/>}>
            <Boolean value={data.user.sendEmailNotificationsSetting}/>
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='place.count'/>}>
            {data.user.placesTotal}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection_subject.count'/>}>
            {data.user.inspectionSubjectsTotal}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.count'/>}>
            {data.user.inspectionsTotal}
          </ShowItemRow>
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <EditButton user={data.user} refetch={refetch}/>
              <PasswordChangeButton user={data.user} refetch={refetch}/>
            </ButtonGroup>
          </Box>
        </WhiteCard>
        <MainTypography variant='h6'><FormattedMessage id='plan.active'/></MainTypography>
        <WhiteCard>
          <PlanShow
            plan={data.user.activePlan}
            inspectionsTotal={data.user.inspectionsTotal}
            inspectionSubjectsTotal={data.user.inspectionSubjectsTotal}
            placesTotal={data.user.placesTotal}/>
          <Box display='flex' justifyContent='flex-end'>
            <PlanSelectButton refetch={refetch} />
          </Box>
        </WhiteCard>
        {data.user.userSubscription &&
          <>
            <MainTypography variant='h6'><FormattedMessage id='plan.subscription'/></MainTypography>
            <SubscriptionShow subscription={data.user.userSubscription}/>
          </>
        }
        {}
      </>
    }
    </>
  );
};

export default Show;
