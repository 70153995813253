import React from 'react';
import { ListItemButton, TextField, Checkbox } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import DragAndDropItem from 'components/drag_and_drop/item';

const Item = ({
  id, index, processing, obj, arrayHelpers, setFieldValue, moveCard, errors, showValue
}) => {
  const intl = useIntl();
  const error = errors?.inspectionFields?.[index]?.name;
  return (
    <DragAndDropItem
      id={id}
      index={index}
      arrayHelpers={arrayHelpers}
      moveCard={moveCard}>
      <TextField
        fullWidth
        label={intl.formatMessage({ id: 'inspection.field.name' })}
        name={`inspectionFields[${index}].name`}
        type='text'
        disabled={processing}
        value={obj.name}
        error={error}
        helperText={error}
        onChange={(e) => {
          setFieldValue(`inspectionFields[${index}].name`, e.target.value);
        }} />
      {showValue && <Checkbox
        margin='dense'
        name={`inspectionFields[${index}].value`}
        checked={obj.value === true}
        onChange={(_event, value) => {
          setFieldValue(`inspectionFields[${index}].value`, value);
        }} />
      }
      <ListItemButton
        onClick={() => {
          arrayHelpers.remove(index);
        }}
        disabled={processing}>
        <FormattedMessage id='button.delete'/>
      </ListItemButton>
    </DragAndDropItem>
  );
};

export default Item;
