import { Stack, Typography } from '@mui/material';
import { StyledLink } from 'components/styles_components/link';
import { InlineSpan } from 'components/styles_components/span';
import React from 'react';
import { UserLink } from 'utils/link';

const Inspector = ({ inspector, ownerRole }) => {
  if (!inspector) {
    return null;
  }

  const content = (
    <Stack textAlign='left'>
      <Typography>
      {inspector.name} {inspector.surname}
      </Typography>
      <Typography
        variant='subtitle2'
        sx={{ color: 'text.secondary' }}>
        {inspector.email}
      </Typography>
    </Stack>
  );

  if (ownerRole) {
    return (
      <StyledLink to={UserLink(inspector.id)}>
        {content}
      </StyledLink>
    );
  } else {
    return (
      <InlineSpan>
        {content}
      </InlineSpan>
    );
  }
};

export default Inspector;
