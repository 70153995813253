
import { gql } from '@apollo/client';
import InspectionFields from 'queries/inspection/inspection_fields_fragment';

export default gql`
  query inspectorInspection($id: ID!) {
    inspectorInspection(id: $id) {
      ...InspectionFields
      notes
      inspectorRole
      inspector {
        id
        name
        surname
        email
        confirmed
      }
      inspectionFields {
        id
        name
        value
        templateFieldId
      }
    }
  }
  ${InspectionFields}
`;

