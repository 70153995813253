import { MenuItem, AppBar } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const SideMenuItem = styled(MenuItem)`
  text-decoration: none;
  color: ${theme.palette.text.primary};
  margin-bottom: 10px;
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${theme.palette.background.default};
  box-shadow: none;
  position: relative;
`;

export const TopMenuItem = styled(MenuItem)`
  text-decoration: none;
  color: ${theme.palette.text.primary};
`;
