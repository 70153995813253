import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import ChangePassword from 'components/user/profile/modal/password_change';

const ChangePasswordButton = ({ user, refetch }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button variant='contained' onClick={() => (addModal(
      <ChangePassword
        user={user}
        onSuccess={() => {
          refetch();
          removeModal();
          setInfo(intl.formatMessage({ id: 'info.user.profile.password.changed' }));
        }}
        handleClose={() => {
        removeModal();
      }}/>
    ))}>
      <FormattedMessage id='button.change_password'/>
    </Button>

  );
};

export default ChangePasswordButton;
