import { Box } from '@mui/material';
import styled from 'styled-components';

export const LogoBox = styled(Box)`
  margin-top: 20px;
  text-align: center;

  &.menu {
    &:hover {
      cursor: pointer;

      img {
        opacity: 0.7;  // Zmniejszenie przezroczystości obrazu na hover
      }
    }
  }
`;
