
import * as yup from 'yup';

const validationSchema = () =>
  yup.object({
    note: yup
      .string('')
      .nullable(),
    doneAt: yup
      .date()
      .nullable(),
    dueDate: yup
      .date()
      .nullable(),
  });

export default validationSchema;
