import React from 'react';
import { IntlProvider } from 'react-intl';
import locales_pl from 'locales/pl.json';

const messages = {
  pl: locales_pl
};

const Intl = ({ children, locale }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export default Intl;
