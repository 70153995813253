import React from 'react';
import { FieldsList, FieldsListItem } from 'components/styles_components/list';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const Index = ({ inspectionFields, showValues = false }) => {
  const icon = (field) => {
    if (field.value) {
      return <CheckIcon />;
    } else {
      return <ClearIcon />;
    }
  };

  const content = (field) => {
    if (showValues) {
      return <>{icon(field)} {field.name}</>;
    } else {
      return field.name;
    }
  };

  return (
    <FieldsList>
      {inspectionFields.map((field) => (
        <FieldsListItem key={`inspection.field-${field.id}`}>
          {content(field)}
        </FieldsListItem>
      ))}
    </FieldsList>
  );
};

export default Index;
