import React from 'react';
import { List } from '@mui/material';
import ListItem from 'components/place/index/list_item';
import ListHeader from 'components/place/index/list_header';

const Index = ({ places }) => {
  return (
    <List>
      <ListHeader visible={places.places.length> 0}/>
      {places.places.map((place) => <ListItem key={place.id} place={place}/>)}
    </List>
  );
};

export default Index;
