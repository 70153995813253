
import { gql } from '@apollo/client';

export default gql`
  query spaceUser($id: ID!) {
    spaceUser(id: $id) {
      id
      email
      name
      surname
      confirmed
    }
  }
`;

