import React, { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

const PaginateContext = createContext();

export const PaginateProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || 1;
  const onPageChange = (page) => {
    setSearchParams({ page });
  };

  return (
    <PaginateContext.Provider value={{
      page: parseInt(page), onPageChange
    }}>
      {children}
    </PaginateContext.Provider>
  );
};

export const usePaginate = () => useContext(PaginateContext);


