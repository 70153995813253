import React, { createContext, useContext, useState } from 'react';

const PaginateContext = createContext();

export const PaginateProvider = ({ children }) => {
  const [page, setPage] = useState(1);

  const onPageChange = (page) => {
    setPage(page);
  };

  return (
    <PaginateContext.Provider value={{
      page: parseInt(page), onPageChange
    }}>
      {children}
    </PaginateContext.Provider>
  );
};

export const usePaginate = () => useContext(PaginateContext);


