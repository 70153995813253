import React from 'react';
import { List } from '@mui/material';
import ListItem from 'components/space_users/index/list_item';
import ListHeader from 'components/space_users/index/list_header';

const Index = ({ spaceUsers }) => {
  return (
    <List>
      <ListHeader visible={spaceUsers.spaceUsers.length> 0}/>
      {spaceUsers.spaceUsers.map((spaceUser) => <ListItem key={spaceUser.id} spaceUser={spaceUser}/>)}
    </List>
  );
};

export default Index;
