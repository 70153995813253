import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import validationSchema from 'validation/user/create';

const Form = ({ onSubmit, onCancel, processing, initialValues }) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema(intl)}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <WideForm onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin='dense'
              id='email'
              name='email'
              label={intl.formatMessage({ id: 'user.email' })}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email) || undefined}
              helperText={formik.touched.email && formik.errors.email}/>
            <Button
              fullWidth
              disabled={!formik.isValid || processing || !formik.dirty}
              color='primary'
              variant='contained'
              type='submit'>
              <FormattedMessage id='button.save' />
            </Button>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                size='small'
                disabled={processing}
                onClick={onCancel}>
                <FormattedMessage id='button.cancel' />
              </Button>
            </Box>
          </WideForm>
        );
      }}
    </Formik>
  );
};

export default Form;
