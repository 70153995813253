import React from 'react';
import { MenuItem } from '@mui/material';
import { SelectFullWidth } from 'components/styles_components/select';
import { FormattedMessage } from 'react-intl';

const RoleSelect = (field) => {
  return (
    <SelectFullWidth
      {...field}>
      <MenuItem value={'viewer'}><FormattedMessage id='place.role.viewer'/></MenuItem>
      <MenuItem value={'admin'}><FormattedMessage id='place.role.admin'/></MenuItem>
    </SelectFullWidth>
  );
};

export default RoleSelect;
