import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker as DatePickerComponent } from '@mui/x-date-pickers/DatePicker';

const DatePicker = (field) => {
  const handleDateChange = (date) => {
    if (date === null) {
      return field.onChange(null);
    }
    // Adjust the date to the local timezone and format it correctly
    const timeZoneDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    field.onChange(timeZoneDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerComponent
        {...field}
        format='dd-MM-yyyy'

        onChange={handleDateChange}/>
    </LocalizationProvider>
  );
};

export default DatePicker;
