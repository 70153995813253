import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import Execute from 'components/inspection/modal/execute';
import InspectionState from 'utils/inspection_state';

const ExecuteButton = ({ inspection, refetch}) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button
      color='success'
      disabled={inspection.state !== InspectionState().PENDING || !inspection.inspectorRole}
      onClick={() => { addModal(
        <Execute
          inspection={inspection}
          onSuccess={() => {
            refetch();
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.inspection.executed' }));
          }}
          handleClose={() => removeModal()}/>
        ); }}>
      <FormattedMessage id='button.do'/>
    </Button>
  );
};

export default ExecuteButton;
