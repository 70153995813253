import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ModalTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';
import { TemplateLink } from 'utils/link';
import { useError } from 'contexts/error';
import CreateMutation from 'mutations/template/create';
import Form from 'components/template/form';

const Create = ({ handleClose, onSuccess }) => {
  const setError = useError();
  const navigate = useNavigate();
  const [createMutation, { loading }] = useMutation(CreateMutation, {
    onCompleted: ({ createTemplate }) => {
      if (onSuccess) {
        onSuccess(createTemplate.template);
      } else {
        navigate(TemplateLink(createTemplate.template.id));
      }
    },
    onError: () => {
      setError('error.template.create');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.template.create'/>
      </ModalTypography>
      <Form
        initialValues={{
          name: '',
          templateFields: [],
        }}
        processing={loading}
        onSubmit={(values) => createMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Create;
