import React from 'react';
import { GridButton } from 'components/styles_components/grid';
import PlanShow from 'components/user/plan/show';

const Item = ({ plan, isActive, isSelected, onClick }) => {
  return <GridButton item xs={12} md={4} >
    <div onClick={() => onClick(plan)} className={isActive ? 'active' : isSelected ? 'selected' : ''}>
      <PlanShow plan={plan}/>
    </div>
  </GridButton>;
};

export default Item;
