import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ListItemIcon, ListItemText, Toolbar, IconButton, Box } from '@mui/material';
import { StyledAppBar, TopMenuItem } from 'components/styles_components/menu';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from 'components/styles_components/link';
import { ProfileLink, LoginLink } from 'utils/link';
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'js-cookie';
import SettingsIcon from '@mui/icons-material/Settings';

const TopMenu = ({sidebarVisible, setSidebarVisible}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove('authenticated');
    navigate(LoginLink());
  };

  const sx = sidebarVisible ? { paddingLeft: theme.width.sideMenu } : {};

  return (
    <StyledAppBar sx={sx}>
      <Toolbar>
        <IconButton onClick={() => {setSidebarVisible(!sidebarVisible);}}>
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <TopMenuItem onClick={logout}>
          <ListItemText><FormattedMessage id='navbar.logout'/></ListItemText>
        </TopMenuItem>
        <ButtonLink to={ProfileLink()}>
        <TopMenuItem
          selected={location.pathname === ProfileLink()}>
          <ListItemIcon>
          <SettingsIcon fontSize='small'/>
          </ListItemIcon>
          <ListItemText><FormattedMessage id='navbar.profile'/></ListItemText>
        </TopMenuItem>
      </ButtonLink>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopMenu;

