import { gql } from '@apollo/client';

export default gql`
  mutation inviteSpaceUser($email: String!) {
    inviteSpaceUser(input: { email: $email }) {
      spaceUser {
        id
      }
    }
  }
`;
