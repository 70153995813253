import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { MainTabs, MainTabsContent } from 'components/styles_components/tab';

const TabsContainer = ({ tabs, content, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const visibleTabs = tabs.filter((tab) => tab.visible);
  const paramsTab = searchParams.get('tab');
  const getTab = () => {
    if (paramsTab && parseInt(paramsTab) >= 0 && parseInt(paramsTab) < visibleTabs.length) {
      return parseInt(paramsTab);
    }
    return 0;
  };
  const onTabChange = (_, tab) => {
    setTab(tab);
    setSearchParams({ tab });
  };
  const [tab, setTab] = useState(getTab());

  return (
    <>
      <MainTabs value={tab} onChange={onTabChange}>
        {visibleTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </MainTabs>
      <MainTabsContent>
        {content[tab]}
      </MainTabsContent>
      {children}
    </>
  );
};

export default TabsContainer;
