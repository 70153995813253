
import * as yup from 'yup';

const validationSchema = (intl) => yup.object({
  name: yup
  .string('')
  .required(intl.formatMessage({ id: 'validation.required' })),
  placeId: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
});

export default validationSchema;
