import React from 'react';
import { Grid, Stack, Typography, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PlaceLink } from 'utils/link';
import { IndexListItemButton } from 'components/styles_components/list';
import Place from 'utils/place';

const ListItem = ({ place }) => {
  const navigate = useNavigate();
  const placeUtil = Place(place);

  return (
    <IndexListItemButton
      className={placeUtil.htmlClass}
      onClick={() => {
        navigate(PlaceLink(place.id));
      }}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={6}>
          <Stack>
            <Typography>
              {place.name}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.secondary' }}>
              {place.street}, {place.zipCode} {place.city}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          textAlign='right'
          xs={3}>
          <Chip
            color={placeUtil.incomingInspectionsColor}
            label={place.incomingInspectionsTotal}/>
        </Grid>
        <Grid
          item
          textAlign='right'
          xs={3}>
          <Chip
            color={placeUtil.overdueInspectionsColor}
            label={place.overdueInspectionsTotal}/>
        </Grid>
      </Grid>
    </IndexListItemButton>
  );
};

export default ListItem;
