import { Modal, Box, Stack } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const CenterModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled(Box)`
  padding: ${theme.padding.content};
  background: ${theme.palette.background.default};
  overflow-y: auto;
  height: 90vh;
  width: 90vw;
`;

export const ModalStack = styled(Stack)`
  align-items: center;
`;
