import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, IconButton } from '@mui/material';
import { CenterModal, ModalContent, ModalStack } from 'components/styles_components/modal';

const Modal = ({ handleClose, children }) => {
  return (
    <CenterModal
      open={true}
      onClose={handleClose}>
      <ModalContent>
        <Stack
          direction='row'
          justifyContent='flex-end'>
          <IconButton
            aria-label='close'
            onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </Stack>
        <ModalStack>
          {children}
        </ModalStack>
      </ModalContent>
    </CenterModal>
  );
};

export default Modal;
