
import React, { useCallback} from 'react';
import { FieldArray } from 'formik';
import { List } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import InspectionFieldItem from 'components/inspection_field/item';
import NewInspectionField from 'components/inspection_field/new';

const DragAndDropField = ({ name, processing, formik, showValue }) => {
  const moveCard = useCallback((dragIndex, hoverIndex, arrayHelpers) => {
    arrayHelpers.move(dragIndex, hoverIndex);
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <FieldArray
        name={name}
        validateOnChange={false}
        render={arrayHelpers => {
          return <List>
            {formik.values.inspectionFields.map((obj, index) => (
              <InspectionFieldItem
                key={index}
                index={index}
                obj={obj}
                showValue={showValue}
                processing={processing}
                moveCard={moveCard}
                arrayHelpers={arrayHelpers}
                setFieldValue={formik.setFieldValue}
                errors={formik.errors}/>
            ))}
            <NewInspectionField
              disabled={processing}
              existedNames={formik.values.inspectionFields.map((field) => field.name)}
              onSubmit={(data) => {
                arrayHelpers.push({ name: data.fieldName });
              }}/>
          </List>;
        }}/>
    </DndProvider>
  );
};

export default DragAndDropField;
