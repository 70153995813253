import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import InspectionResult from 'utils/inspection_result';

const ResultSelect = (field) => {
  return (
    <FormControl
      fullWidth
      margin={field.margin}>
      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      <Select
        name={field.name}
        id={field.id}
        label={field.label}
        labelId={`${field.name}-label`}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        error={field.error}>
        <MenuItem value={InspectionResult().SUCCESS}><FormattedMessage id={`inspection.result.${InspectionResult().SUCCESS}`}/></MenuItem>
        <MenuItem value={InspectionResult().FAILURE}><FormattedMessage id={`inspection.result.${InspectionResult().FAILURE}`}/></MenuItem>
      </Select>
    </FormControl>
  );
};

export default ResultSelect;
