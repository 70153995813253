import React from 'react';
import { Grid, Breadcrumbs } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HomeLink } from 'utils/link';
import { BreadCrumbLink } from 'components/styles_components/link';

const BreadcrumbContainer = ({ children }) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'>
      <Breadcrumbs
        aria-label='breadcrumb'
        separator='-'>
        <BreadCrumbLink to={HomeLink()}>
          <FormattedMessage id='breadcrumb.home'/>
        </BreadCrumbLink>
        {children}
      </Breadcrumbs>
    </Grid>
  );
};

export default BreadcrumbContainer;

