import React from 'react';
import { Chip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import InspectionResult from 'utils/inspection_result';


const Result = ({ result }) => {
  if (!result) {
    return null;
  }

  return (
    <Chip
      size='small'
      color={InspectionResult(result).color}
      label={<FormattedMessage id={`inspection.result.${result}`} />}/>
  );
};

export default Result;
