import React from 'react';
import { ListItem, Checkbox, Typography, Grid } from '@mui/material';

const ExecuteItem = ({
  index, processing, obj, setFieldValue
}) => {
  return (
    <ListItem
      disableGutters
      key={index}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography>
          {obj.name}
        </Typography>
        <Checkbox
          margin='dense'
          disabled={processing}
          label={obj.name}
          name={`inspectionFields[${index}].value`}
          checked={obj.value === true}
          onChange={(_event, value) => {
            setFieldValue(`inspectionFields[${index}].value`, value);
          }} />
      </Grid>
    </ListItem>
  );
};

export default ExecuteItem;
