import { Grid } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const GridButton = styled(Grid)`
  max-width: 380px;
  > div {
    cursor: pointer;
    border: 1px solid ${theme.palette.secondary.main};
    border-radius: ${theme.radius.card};
    padding: ${theme.padding.content};
    height: 100%;
    &:hover {
      background-color: ${theme.palette.columnBackground.hover};
    }
    &.selected {
      background-color: ${theme.palette.columnBackground.hover};
    }
    &.active {
      border-color: ${theme.palette.primary.main};
      border-width: 2px;
    }
  }
`;
