
import { gql } from '@apollo/client';

export default gql`
  query inspectionSubject($id: ID!) {
    inspectionSubject(id: $id) {
      id
      name
      adminRole
      canAddInspection
      serialNumber
      inspectionsTotal
      incomingInspectionsTotal
      overdueInspectionsTotal
      place {
        id
        name
        street
        city
        zipCode
      }
    }
  }
`;

