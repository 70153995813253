import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { UserLink } from 'utils/link';
import Create from 'components/space_users/modal/create';
import AddIcon from '@mui/icons-material/Add';

const CreateButton = () => {
  const intl = useIntl();
  const setInfo = useInfo();
  const {addModal, removeModal} = useModal();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => addModal(
        <Create
          handleClose={() => removeModal()}
          onSuccess={(spaceUser) => {
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.user.invited' }));
            navigate(UserLink(spaceUser.id));
          }}/>
      )}
      variant='contained'
      color='success'>
      <FormattedMessage id='button.user.invite'/> <AddIcon/>
    </Button>
  );
};

export default CreateButton;
