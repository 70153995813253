
import theme from 'theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  color: ${theme.palette.link.main};
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  &:hover {
    color: ${theme.palette.link.hover};
  }
`;

export const BreadCrumbLink = styled(Link)`
  color: ${theme.palette.link.main};
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    color: ${theme.palette.link.hover};
  }
  font-size: ${theme.typography.fontSizeSmall}px;
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
`;
