import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import Form from 'components/user/profile/modal/password_change/form';

const Show = ({ handleClose, onSuccess }) => {
  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.user.change_password'/>
      </ModalTypography>
      <Form
        initialValues={{
          currentPassword: '',
          password: '',
        }}
        onSuccess={onSuccess}
        onCancel={handleClose}/>
    </>
  );
};

export default Show;
