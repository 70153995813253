import React, { createContext, useContext, useState } from 'react';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const InfoContext = createContext();

export const InfoProvider = ({ children }) => {
  const [info, setInfo] = useState(null);

  return (
    <InfoContext.Provider value={setInfo}>
      <Snackbar
        open={!!info}
        autoHideDuration={6000}
        onClose={() => setInfo(null)}
        message={info}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setInfo(null)}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }/>
      {children}
    </InfoContext.Provider>
  );
};

export const useInfo = () => useContext(InfoContext);


