import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Button } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useError } from 'contexts/error';
import { useModal } from 'contexts/modal';
import AddIcon from '@mui/icons-material/Add';
import PlacesQuery from 'queries/place/index';
import PlaceQuery from 'queries/place/show';
import OptionableAutocomplete from 'components/form/autocomplete/optionable';
import CreatePlace from 'components/place/modal/create';

const Place = ({ id, label, name, onChange, onBlur, value, error, helperText, setValue }) => {
  const setError = useError();
  const {addModal, removeModal} = useModal();
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const { data = { user: { canAddPlace: true }, places: { places: [], placesTotal: 0 } }, loading } = useQuery(
    PlacesQuery,
    {
      variables: { page: 1, name: searchValue },
      onError: () => {
        setError('error.place.index');
      }
    }
  );
  const [loadDefaultPlace, defaultPlaceData] = useLazyQuery(
    PlaceQuery, {
      variables: { id: value },
      onCompleted: (data) => {
        setDefaultValue({ label: data.place.name, value: data.place.id });
      },
      onError: () => {
        setError('error.place.show');
      }
    }
  );

  const options = data.places.places.map(place => ({ label: place.name, value: place.id }));

  const onChangeInternal = (evt, value) => {
    setDefaultValue(value);
    onChange(evt, value);
  };

  const addButton = () => {
    if (!data.user.canAddPlace) return null;

    return (
      <Button
        onClick={() => addModal(
          <CreatePlace
            handleClose={() => removeModal()}
            name={searchValue}
            onSuccess={(place) => {
              setDefaultValue({ label: place.name, value: place.id });
              removeModal();
              setValue(name, place.id);
            }}/>
        )}
        variant='contained'
        color='success'>
        <AddIcon/>
      </Button>
    );
  };

  useEffect(() => {
    if (value && !defaultValue) {
      loadDefaultPlace();
    }
  }, [defaultValue, loadDefaultPlace, value]);

  return (
    <>
      <OptionableAutocomplete
        id={id}
        label={label}
        options={options}
        name={name}
        onChange={onChangeInternal}
        loadingText={intl.formatMessage({ id: 'autocomplete.place.loading' })}
        noOptionsText={
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <FormattedMessage id='autocomplete.place.no_options'/>
            {addButton()}
          </Grid>
        }
        value={defaultValue}
        onBlur={onBlur}
        loading={loading || defaultPlaceData.loading}
        error={error}
        helperText={helperText}
        onSearch={setSearchValue}/>
    </>
  );
};

export default Place;
