import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useError } from 'contexts/error';
import TemplateQuery from 'queries/template/show';
import TemplatesQuery from 'queries/template/index';
import OptionableAutocomplete from 'components/form/autocomplete/optionable';

const Template = ({ id, label, name, fieldsName, onChange, onBlur, value, values, setValue, error, helperText }) => {
  const setError = useError();
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const { data = { templates: { templates: [], templatesTotal: 0 } }, loading } = useQuery(
    TemplatesQuery,
    {
      variables: { page: 1, name: searchValue },
      onError: () => {
        setError('error.template.index');
      }
    }
  );
  const [loadDefaultTemplate, defaultTemplateData = {}] = useLazyQuery(
    TemplateQuery, {
      variables: { id: value },

      onCompleted: (data) => {
        setDefaultValue({ label: data.template.name, value: data.template.id });
        let fields = [...values.inspectionFields, ...data.template.templateFields.map(field => ({ name: field.name, templateFieldId: field.id }))];
        setValue(fieldsName, fields);
        onChange({ target: { name: name, value: value } });
      },
      onError: () => {
        setError('error.template.show');
      }
    }
  );

  const options = data.templates.templates.map(template => ({ label: template.name, value: template.id }));

  useEffect(() => {
    if (value) {
      let fields = [];
      values.inspectionFields.forEach((field) => {
        if (!field.templateFieldId) {
          fields.push(field);
        }
      });
      setValue(fieldsName, fields);
      loadDefaultTemplate();
    }
  }, [value]);

  return (
    <OptionableAutocomplete
      id={id}
      label={label}
      options={options}
      name={name}
      onChange={onChange}
      loadingText={intl.formatMessage({ id: 'autocomplete.template.loading' })}
      noOptionsText={
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <FormattedMessage id='autocomplete.template.no_options'/>
        </Grid>
      }
      value={defaultValue}
      onBlur={onBlur}
      loading={loading || defaultTemplateData.loading}
      error={error}
      helperText={helperText}
      onSearch={setSearchValue}/>
  );
};

export default Template;
