import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useError } from 'contexts/error';
import { BreadCrumbLink } from 'components/styles_components/link';
import { ContentContainer } from 'components/styles_components/container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { UsersLink } from 'utils/link';
import UserQuery from 'queries/space/user';
import Loader from 'components/loader';
import BreadcrumbContainer from 'components/breadcrumb_container';
import ShowItem from 'components/space_users/show_item';

const Place = () => {
  const { id } = useParams();
  const setError = useError();
  const { data = { spaceUser: { places: [] } }, loading } = useQuery(
    UserQuery,
    {
      variables: { id },
      onError: () => {
        setError('error.user.show');
      }
    }
  );

  if (loading) { return <Loader />; }

  return (
    <ContentContainer maxWidth='xl'>
      <BreadcrumbContainer>
        <BreadCrumbLink to={UsersLink()}>
          <FormattedMessage id='breadcrumb.users.index'/>
        </BreadCrumbLink>
        <BreadCrumbTypography>{data.spaceUser.email}</BreadCrumbTypography>
      </BreadcrumbContainer>
      <ShowItem spaceUser={data.spaceUser} />
    </ContentContainer>
  );
};

export default Place;
