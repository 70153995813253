import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { MainDiv } from 'components/styles_components/container';
import { PageContainer } from 'components/styles_components/container';
import Cookies from 'js-cookie';
import SideMenu from 'components/layout/side_menu';
import Footer from 'components/layout/footer';
import TopMenu from 'components/layout/top_menu';

const PrivateRoute = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);
  const accessToken = Cookies.get('authenticated');
  const style = sidebarVisible ? { marginLeft: theme.width.sideMenu } : {};

  if (accessToken) {
    return (
      <>
        <TopMenu
          onSidebarToogle={setSidebarVisible}
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}/>
        <MainDiv style={style}>
          <SideMenu visible={sidebarVisible}/>
          <PageContainer>
            <Outlet/>
          </PageContainer>
        </MainDiv>
        <Footer/>
      </>
    );
  }
  return <Navigate to='/users/login'/>;
};

export default PrivateRoute;
