import { gql } from '@apollo/client';

export default gql`
  mutation editTemplate($id: ID!, $name: String!, $templateFields: [TemplateFieldInput!]!) {
    editTemplate(input: { id: $id, name: $name, templateFields: $templateFields }) {
      template {
        id
      },
    }
  }
`;
