import React from 'react';
import { Formik } from 'formik';
import { TextField, Box, Button, Grid } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import PlaceAutocomplete from 'components/form/autocomplete/place';
import TemplateAutocomplete from 'components/form/autocomplete/template';
import InspectionSubjectAutocomplete from 'components/form/autocomplete/inpection_subject';
import UserAutocomplete from 'components/form/autocomplete/user';
import PeriodSelect from 'components/form/period_select';
import DatePicker from 'components/form/date_picker';
import validationSchema from 'validation/inspection/create';
import DragAndDropField from 'components/inspection_field/drag_and_drop_field';
import { FormHintTypography } from 'components/styles_components/typography';

const Form = ({ initialValues, processing, onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(intl)}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <WideForm onSubmit={formik.handleSubmit}>
            <PlaceAutocomplete
              margin='dense'
              id='placeId'
              name='placeId'
              label={intl.formatMessage({ id: 'place' })}
              value={formik.values.placeId}
              onChange={formik.handleChange}
              setValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.touched.placeId && Boolean(formik.errors.placeId) || undefined}
              helperText={formik.touched.placeId && formik.errors.placeId} />
            <InspectionSubjectAutocomplete
              margin='dense'
              id='inspectionSubjectId'
              name='inspectionSubjectId'
              setValue={formik.setFieldValue}
              label={intl.formatMessage({ id: 'inspection_subject' })}
              placeId={formik.values.placeId}
              value={formik.values.inspectionSubjectId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.inspectionSubjectId && Boolean(formik.errors.inspectionSubjectId) || undefined}
              helperText={formik.touched.inspectionSubjectId && formik.errors.inspectionSubjectId} />
            <TextField
              fullWidth
              margin='dense'
              id='name'
              name='name'
              label={intl.formatMessage({ id: 'name' })}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) || undefined}
              helperText={formik.touched.name && formik.errors.name} />
            <UserAutocomplete
              margin='dense'
              id='inspectorId'
              name='inspectorId'
              setValue={formik.setFieldValue}
              label={intl.formatMessage({ id: 'inspector' })}
              value={formik.values.inspectorId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.inspectorId && Boolean(formik.errors.inspectorId) || undefined}
              helperText={formik.touched.inspectorId && formik.errors.inspectorId} />
            <TemplateAutocomplete
              margin='dense'
              id='templateId'
              name='templateId'
              fieldsName='inspectionFields'
              label={intl.formatMessage({ id: 'inspection.template' })}
              value={formik.values.templateId}
              values={formik.values}
              onChange={formik.handleChange}
              setValue={formik.setFieldValue}
              onBlur={formik.handleBlur} />
              <FormHintTypography>
                <FormattedMessage id='form.inspection.create.template.hint' />
            </FormHintTypography>
            <Grid container columnSpacing={2} className='margin-top-10'>
              <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  margin='dense'
                  type='number'
                  id='periodNumber'
                  name='periodNumber'
                  inputProps={{ min: 1 }}
                  label={intl.formatMessage({ id: 'inspection.period_number' })}
                  value={formik.values.periodNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.periodNumber && Boolean(formik.errors.periodNumber) || undefined}
                  helperText={formik.touched.periodNumber && formik.errors.periodNumber} />
              </Grid>
              <Grid item xs={6} md={9}>
                <PeriodSelect
                  fullWidth
                  margin='dense'
                  id='periodCycle'
                  name='periodCycle'
                  label={intl.formatMessage({ id: 'inspection.period_cycle' })}
                  value={formik.values.periodCycle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.periodCycle && Boolean(formik.errors.periodCycle) || undefined} />
              </Grid>
              <Grid item xs={12}>
                <FormHintTypography>
                  <FormattedMessage id='form.inspection.create.period.hint' />
                </FormHintTypography>
                </Grid>
            </Grid>
            <Grid>
              <DatePicker
                className='width-100 margin-top-10'
                name='doneAt'
                id='doneAt'
                label={intl.formatMessage({ id: 'form.inspection.create.done_at' })}
                value={formik.values.doneAt}
                onChange={(value) => formik.setFieldValue('doneAt', value)} />
                <FormHintTypography>
                  <FormattedMessage id='form.inspection.create.done_at.hint' />
                </FormHintTypography>
            </Grid>
            <Grid className='margin-top-10'>
              <DatePicker
                className='width-100'
                name='dueDate'
                id='dueDate'
                label={intl.formatMessage({ id: 'form.inspection.create.due_date' })}
                value={formik.values.dueDate}
                onChange={(value) => formik.setFieldValue('dueDate', value)} />
              <FormHintTypography>
                <FormattedMessage id='form.inspection.create.due_date.hint' />
              </FormHintTypography>
            </Grid>
            <TextField
              fullWidth
              margin='dense'
              type='notes'
              id='notes'
              name='notes'
              label={intl.formatMessage({ id: 'inspection.notes' })}
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.notes && Boolean(formik.errors.notes) || undefined}
              helperText={formik.touched.notes && formik.errors.notes} />
            <DragAndDropField
              name='inspectionFields'
              formik={formik}
              processing={processing} />
            <Button
              fullWidth
              className='margin-top-10'
              disabled={!formik.isValid || processing}
              color='primary'
              variant='contained'
              type='submit'>
              <FormattedMessage id='button.save' />
            </Button>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                size='small'
                disabled={processing}
                onClick={onCancel}>
                <FormattedMessage id='button.cancel' />
              </Button>
            </Box>
          </WideForm>
        );
      }}
    </Formik>
  );
};

export default Form;
