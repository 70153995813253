import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useError } from 'contexts/error';
import UsersQuery from 'queries/space/users';
import UserQuery from 'queries/space/user';
import OptionableAutocomplete from 'components/form/autocomplete/optionable';

const User = ({ id, label, name, onChange, onBlur, value, error, helperText }) => {
  const setError = useError();
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const { data = { spaceUsers: { spaceUsers: [], spaceUsersTotal: 0 } }, loading } = useQuery(
    UsersQuery,
    {
      variables: { page: 1, name: searchValue },
      onError: () => {
        setError('error.user.index');
      }
    }
  );
  const [loadDefaultUser, defaultUserData] = useLazyQuery(
    UserQuery, {
      variables: { id: value },
      onCompleted: (data) => {
        setDefaultValue({ label: data.spaceUser.email, value: data.spaceUser.id });
      },
      onError: () => {
        setError('error.user.show');
      }
    }
  );

  const options = data.spaceUsers.spaceUsers.map(user => ({ label: user.email, value: user.id }));

  const onChangeInternal = (evt, value) => {
    setDefaultValue(value);
    onChange(evt, value);
  };

  useEffect(() => {
    if (value && !defaultValue) {
      loadDefaultUser();
    }
  }, [defaultValue, loadDefaultUser, value]);

  return (
    <>
      <OptionableAutocomplete
        id={id}
        label={label}
        options={options}
        name={name}
        onChange={onChangeInternal}
        loadingText={intl.formatMessage({ id: 'autocomplete.user.loading' })}
        noOptionsText={
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <FormattedMessage id='autocomplete.user.no_options'/>
          </Grid>
        }
        value={defaultValue}
        onBlur={onBlur}
        loading={loading || defaultUserData.loading}
        error={error}
        helperText={helperText}
        onSearch={setSearchValue}/>
    </>
  );
};

export default User;
