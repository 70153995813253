import React from 'react';
import { Formik } from 'formik';
import { TextField, Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePicker from 'components/form/date_picker';
import validationSchema from 'validation/inspection/cancel';

const CancelForm = ({ initialValues, processing, onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}>
      {formik => (
        <WideForm onSubmit={formik.handleSubmit}>
          <DatePicker
            className='width-100 margin-top-10'
            name='canceledAt'
            id='canceledAt'
            label={intl.formatMessage({ id: 'inspection.canceled_at' })}
            value={formik.values.canceledAt}
            onChange={(value) => formik.setFieldValue('canceledAt', value)}/>
          <TextField
            fullWidth
            margin='dense'
            type='notes'
            id='notes'
            name='notes'
            label={intl.formatMessage({ id: 'inspection.notes' })}
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.notes && Boolean(formik.errors.notes) || undefined}
            helperText={formik.touched.notes && formik.errors.notes}/>
          <Button
            fullWidth
            className='margin-top-10'
            disabled={!formik.isValid || processing}
            color='primary'
            variant='contained'
            type='submit'>
            <FormattedMessage id='button.save' />
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel' />
            </Button>
          </Box>
        </WideForm>
      )}
    </Formik>
  );
};

export default CancelForm;
