import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const initInspectionFilterValues = {
  name: '',
  state: '',
  placeId: null,
  inspectionSubjectId: null,
  dueDateFrom: null, dueDateTo: null,
  doneAtFrom: null, doneAtTo: null,
  canceledAtFrom: null, canceledAtTo: null
};

export const initInspectionSubjectFilterValues = {
  name: '',
  serialNumber: '',
};

export const initPlaceFilterValues = {
  name: '',
};

export const initUserFilterValues = {
  name: '',
  email: '',
};

export const initTemplateFilterValues = {
  name: '',
};


export const FilterProvider = ({ children }) => {
  const [inspectionsFilter, setInspectionsFilter] = useState(initInspectionFilterValues);
  const [inspectionSubjectsFilter, setInspectionSubjectsFilter] = useState(initInspectionSubjectFilterValues);
  const [placesFilter, setPlacesFilter] = useState(initPlaceFilterValues);
  const [templatesFilter, setTemplatesFilter] = useState(initTemplateFilterValues);
  const [usersFilter, setUsersFilter] = useState(initUserFilterValues);

  return (
    <FilterContext.Provider value={{
      inspectionsFilter, setInspectionsFilter,
      inspectionSubjectsFilter, setInspectionSubjectsFilter,
      placesFilter, setPlacesFilter,
      templatesFilter, setTemplatesFilter,
      usersFilter, setUsersFilter,
    }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);


