import React from 'react';
import { Formik } from 'formik';
import { TextField, Checkbox, FormControlLabel, Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import validationSchema from 'validation/user/edit';

const Form = ({initialValues, processing, onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {formik => (
        <WideForm onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin='dense'
            id='name'
            name='name'
            label={intl.formatMessage({ id: 'user.firstname' })}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name) || undefined}
            helperText={formik.touched.name && formik.errors.name}/>
          <TextField
            fullWidth
            margin='dense'
            id='surname'
            name='surname'
            label={intl.formatMessage({ id: 'user.surname' })}
            value={formik.values.surname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.surname && Boolean(formik.errors.surname) || undefined}
            helperText={formik.touched.surname && formik.errors.surname}/>
          <FormControlLabel
            label={<FormattedMessage id='settings.send_email_notifications'/>}
            control={
              <Checkbox
                margin='dense'
                id='sendEmailNotificationsSetting'
                name='sendEmailNotificationsSetting'
                checked={formik.values.sendEmailNotificationsSetting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.sendEmailNotificationsSetting && Boolean(formik.errors.sendEmailNotificationsSetting) || undefined} />
            }/>
          <Button
            className='margin-top-10'
            fullWidth
            disabled={!formik.isValid || processing || !formik.dirty}
            color='primary'
            variant='contained'
            type='submit'>
              <FormattedMessage id='button.save'/>
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel'/>
            </Button>
          </Box>
        </WideForm>
      )}
    </Formik>
  );
};

export default Form;
