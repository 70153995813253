import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TemplateLink } from 'utils/link';
import { IndexListItemButton } from 'components/styles_components/list';

const ListItem = ({ template }) => {
  const navigate = useNavigate();

  return (
    <IndexListItemButton key={template.id}
      onClick={() => {
        navigate(TemplateLink(template.id));
      }}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {template.name}
          </Typography>
        </Grid>
      </Grid>
    </IndexListItemButton>
  );
};

export default ListItem;
