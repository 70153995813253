import React from 'react';
import { Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import Loader from 'components/loader';
import EmptyInfo from 'components/empty_info';
import UsersQuery from 'queries/place/space_users_roles';
import CreateButton from 'components/assigned_users/button/create';
import AssignedUsers from 'components/assigned_users/index';

const IndexContainer = ({ placeId }) => {
  const setError = useError();
  const { data = { place: { spaceUserRoles: []  } }, loading, refetch } = useQuery(
    UsersQuery,
    {
      variables: {
        id: placeId,
      },
      onError: () => {
        setError('error.users.index');
      }
    }
  );

  return (
    <>
      {loading
        ? <Loader/>
        : <>
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <CreateButton placeId={placeId} onSuccess={refetch}/>
            </ButtonGroup>
          </Box>
          <EmptyInfo
            visible={!loading && data.place.spaceUserRoles === 0}
            description={<FormattedMessage id='empty.users.title'/>} />
          <AssignedUsers place={data.place} refetch={refetch} />
        </>
      }
      <DividerContainer/>
    </>
  );
};

export default IndexContainer;
