class InspectionSubject {
  constructor(inspectionSubject) {
    this.inspectionSubject = inspectionSubject;
  }

  get htmlClass() {
    return this.inspectionSubject.overdueInspectionsTotal ? 'overdue' : this.inspectionSubject.incomingInspectionsTotal ? 'incoming' : '';
  }

  get incomingInspectionsColor() {
    return this.inspectionSubject.incomingInspectionsTotal > 0 ? 'warning' : 'info';
  }

  get overdueInspectionsColor() {
    return this.inspectionSubject.overdueInspectionsTotal > 0 ? 'error' : 'info';
  }

  get hasInspections() {
    return this.inspectionSubject.inspectionsTotal > 0;
  }

  static create(inspectionSubject) {
    return new InspectionSubject(inspectionSubject);
  }
}

const InspectionSubjectFactory = (inspectionSubject) => new InspectionSubject(inspectionSubject);

export default InspectionSubjectFactory;
