import React from 'react';
import { LogoBox } from 'components/styles_components/box';
import logo from 'assets/images/logo.png';

const Logo = ({ width = 200, className='' }) => {

  return (
    <LogoBox className={className}>
      <img src={logo} alt='Logo' width={width}/>
    </LogoBox>
  );
};

export default Logo;

