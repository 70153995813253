import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import ProfileShow from 'components/user/profile/show';
import BreadcrumbContainer from 'components/breadcrumb_container';
import { BreadCrumbTypography } from 'components/styles_components/typography';

const Profile = () => {
  return (
    <ContentContainer maxWidth='xl'>
      <BreadcrumbContainer>
        <BreadCrumbTypography><FormattedMessage id='breadcrumb.usersprofile'/></BreadCrumbTypography>
      </BreadcrumbContainer>
      <ProfileShow/>
    </ContentContainer>
  );
};

export default Profile;
