
import * as yup from 'yup';

const validationSchema = (intl) => yup.object({
  id: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  spaceUserId: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  role: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
});

export default validationSchema;
