import React from 'react';
import { Grid } from '@mui/material';
import { IndexListItemHeader } from 'components/styles_components/list';
import { FormattedMessage } from 'react-intl';

const ListHeader = ({ visible }) => {
  if (!visible) { return null; }

  return (
    <IndexListItemHeader>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={3} textAlign='left'>
          <FormattedMessage id='user.email'/>
        </Grid>
        <Grid item xs={3} textAlign='left'>
          <FormattedMessage id='user.firstname_and_surname'/>
        </Grid>
        <Grid item xs={3} textAlign='left'>
          <FormattedMessage id='place.role'/>
        </Grid>
        <Grid item xs={3} textAlign='right'>
          <FormattedMessage id='actions'/>
        </Grid>
      </Grid>
    </IndexListItemHeader>
  );
};

export default ListHeader;
