import React, { useState } from 'react';
import { List, Button, Pagination, Box, Drawer } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { FilterProvider, useFilter, initInspectionFilterValues } from 'contexts/filter';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import ListHeader from 'components/inspection/index/list_header';
import FilterForm from 'components/inspection/filter_form';
import FilterTags from 'components/filter/tags';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InspectionsQuery from 'queries/inspection/index';
import Loader from 'components/loader';
import ListItem from 'components/inspection/index/list_item';
import EmptyInfo from 'components/empty_info';

const RelatedInspectionsIndex = ({ id, identifier, page, parentInspectionState, onPageChange }) => {
  const { inspectionsFilter, setInspectionsFilter } = useFilter();
  const [filterVisible, setFilterVisible] = useState(false);
  const setError = useError();
  const { data = { inspections: { inspections: []} }, loading } = useQuery(
    InspectionsQuery, {
      variables: {
        ...inspectionsFilter,
        page: page,
        identifier: identifier,
        // To refresh the list after update/done inspection
        parentInspectionState: parentInspectionState,
        notConditions: {
          id: id,
        },
        sortBy: 'number desc',
        state: inspectionsFilter.state.length === 0 ? null : inspectionsFilter.state,
      },
      onError: () => {
        setError('error.inspection.index');
      }
    }
  );

  return (
    <FilterProvider>
      {filterVisible &&
        <Drawer
          anchor='right'
          variant='persistent'
          open={filterVisible}
          onClose={() => { setFilterVisible(false);} }>
          <FilterForm
            initialValues={inspectionsFilter}
            defaultValues={initInspectionFilterValues}
            onClose={() => { setFilterVisible(false);} }
            onSubmit={(values) => {
              onPageChange(1);
              setInspectionsFilter(values);
            }}/>
        </Drawer>
      }
      { loading
        ? <Loader/>
        : <>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              onClick={() => setFilterVisible(!filterVisible)}
              variant='contained'
              color={JSON.stringify(initInspectionFilterValues) === JSON.stringify(inspectionsFilter) ? 'primary' : 'warning'}>
              <FormattedMessage id='button.filter'/> <FilterAltIcon/>
            </Button>
          </Box>
          <FilterTags
            filterValues={inspectionsFilter}
            defaultValues={initInspectionFilterValues}
            onFilterChange={setInspectionsFilter}/>
          <EmptyInfo
            visible={data.inspections.inspections.length === 0}
            description={<FormattedMessage id='empty.inspections.title'/>}/>
          <List>
            <ListHeader visible={data.inspections.inspections.length> 0}/>
            {data.inspections.inspections.map((inspection) => (
              <ListItem key={inspection.id} inspection={inspection} />
            ))}
          </List>
          <Box display='flex' justifyContent='center'>
          { data.inspections.inspectionsTotalPages> 1 &&
            <Pagination
              count={data.inspections.inspectionsTotalPages}
              page={page}
              onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
          }
          </Box>
        </>
      }
      <DividerContainer/>
    </FilterProvider>
  );
};

export default RelatedInspectionsIndex;
