
import { gql } from '@apollo/client';

export default gql`
  query places($page: Int, $name: String) {
    user {
      canAddPlace
    }
    places(page: $page, name: $name) {
      placesTotalPages
      placesTotal
      perPage
      hasNextPage
      places {
        id
        name
        street
        zipCode
        city
        inspectionSubjectsTotal
        inspectionsTotal
        incomingInspectionsTotal
        overdueInspectionsTotal
      }
    }
  }
`;

