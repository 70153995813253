
import { gql } from '@apollo/client';

export default gql`
  query userProfile {
    user {
      email
      name
      surname
      sendPushNotificationsSetting
      sendEmailNotificationsSetting
      inspectionSubjectsTotal
      inspectionsTotal
      placesTotal
      activePlan {
        id
        name
        price
        billingInterval
        placesLimit
        inspectionSubjectsLimit
        inspectionsLimit
      }
      userSubscription {
        id
        startDate
        endDate
        active
        plan {
          id
          name
          price
          billingInterval
          placesLimit
          inspectionSubjectsLimit
          inspectionsLimit
        }
      }
    }
  }
`;

