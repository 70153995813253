import React from 'react';
import { useInfo } from 'contexts/info';
import { useFormik } from 'formik';
import { Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import PasswordField from 'components/form/password';
import ChangePasswordMutation from 'mutations/user/change_password';
import validationSchema from 'validation/user/password_change';

const Form = ({initialValues, onSuccess, onCancel}) => {
  const intl = useIntl();
  const setInfo = useInfo();

  const [changePasswordMutation, { loading }] = useMutation(ChangePasswordMutation, {
    onCompleted: (data) => {
      setInfo(intl.formatMessage({ id: 'info.user.profile.password.changed' }));
      onSuccess(data);
    },
    onError: () => {
      formik.setErrors({currentPassword: intl.formatMessage({ id: 'error.user.password' })});
    },
  });

  const formik = useFormik({
    validationSchema: validationSchema(intl),
    initialValues: initialValues,
    onSubmit: (values) => {
      changePasswordMutation({ variables: values });
    },
  });

  return (
    <WideForm onSubmit={formik.handleSubmit}>
      <PasswordField
        fullWidth
        margin='dense'
        id='currentPassword'
        name='currentPassword'
        label={intl.formatMessage({ id: 'user.current_password' })}
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword) || undefined}
        helperText={formik.touched.currentPassword && formik.errors.currentPassword}/>
      <PasswordField
        fullWidth
        margin='dense'
        id='password'
        name='password'
        label={intl.formatMessage({ id: 'user.password' })}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password) || undefined}
        helperText={formik.touched.password && formik.errors.password}/>
      <Button
        className='margin-top-10'
        fullWidth
        disabled={!formik.isValid || loading || !formik.dirty}
        color='primary'
        variant='contained'
        type='submit'>
          <FormattedMessage id='button.save'/>
      </Button>
      <Box display='flex' justifyContent='flex-end'>
        <Button
          variant='text'
          size='small'
          disabled={loading}
          onClick={onCancel}>
          <FormattedMessage id='button.cancel'/>
        </Button>
      </Box>
    </WideForm>
  );
};

export default Form;
