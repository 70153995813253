import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { InspectionLink } from 'utils/link';
import Create from 'components/inspection/modal/create';
import AddIcon from '@mui/icons-material/Add';

const CreateButton = ({ disabled, placeId, inspectionSubjectId }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Button
      disabled={disabled}
      onClick={() => addModal(
        <Create
          handleClose={() => removeModal()}
          placeId={placeId}
          inspectionSubjectId={inspectionSubjectId}
          onSuccess={(inspection) => {
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.inspection.created' }));
            navigate(InspectionLink(inspection.id));
          }}/>
      )}
      variant='contained'
      color='success'>
      <FormattedMessage id='button.add.inspection'/> <AddIcon/>
    </Button>
  );
};

export default CreateButton;
