import { gql } from '@apollo/client';

export default gql`
  mutation cancelInspection($id: ID!, $notes: String, $canceledAt: ISO8601DateTime) {
    cancelInspection(input: { id: $id, notes: $notes, canceledAt: $canceledAt }) {
      inspection {
        id
        name
      }
    }
  }
`;