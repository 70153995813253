import React from 'react';
import { Grid, Typography, Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { StyledLink } from 'components/styles_components/link';
import { WhiteCard } from 'components/styles_components/card';
import { PlaceLink } from 'utils/link';
import ShowItemRow from 'components/item/show_item_row';
import InspectionSubject from 'utils/inspection_subject';
import EditButton from 'components/inspection_subject/button/edit';
import DeleteButton from 'components/inspection_subject/button/delete';
import GoogleLinkButton from 'components/navigation/google_link_button';

const ShowItem = ({ inspectionSubject, refetch }) => {
  const { place={} } = inspectionSubject;

  return (
    <>
      <WhiteCard className={InspectionSubject(inspectionSubject).htmlClass}>
        <Typography variant='h6'><FormattedMessage id='inspection_subject'/></Typography>
        <Grid container
          direction='row'
          justifyContent='space-between'
          spacing={2}
          alignItems='center'>
          <ShowItemRow label={<FormattedMessage id='name'/>}>
            {inspectionSubject.name}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection_subject.serial_number'/>}>
            {inspectionSubject.serialNumber || '-'}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.overdue.count'/>}>
            {inspectionSubject.overdueInspectionsTotal}
          </ShowItemRow>
          <ShowItemRow label={<FormattedMessage id='inspection.incoming.count'/>}>
            {inspectionSubject.incomingInspectionsTotal}
          </ShowItemRow>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            display='flex'
            justifyContent='flex-end'>
            <ButtonGroup variant='contained'>
              <EditButton
                inspectionSubject={inspectionSubject}
                refetch={refetch} />
              <DeleteButton
                inspectionSubject={inspectionSubject} />
            </ButtonGroup>
          </Box>
        </Grid>
      </WhiteCard>

      <WhiteCard>
        <Typography variant='h6'><FormattedMessage id='place'/></Typography>
        <ShowItemRow label={<FormattedMessage id='place'/>}>
          <StyledLink to={PlaceLink(place.id)}>
            {place.name}
          </StyledLink>
        </ShowItemRow>

        <ShowItemRow label={<FormattedMessage id='place.street'/>}>{place.street}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.zip_code'/>}>{place.zipCode}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.city'/>}>{place.city}</ShowItemRow>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <GoogleLinkButton
              place={place} />
          </ButtonGroup>
        </Box>
      </WhiteCard>
    </>
  );
};

export default ShowItem;
