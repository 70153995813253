import { gql } from '@apollo/client';

export default gql`
  mutation editInspection(
    $id: ID!, $name: String!, $result: Int, $periodCycle: String!, $periodNumber: Int!,
    $dueDate: ISO8601DateTime!, $doneAt: ISO8601DateTime, $notes: String,
    $state: Int!, $inspectionFields: [InspectionFieldInput!], $inspectorId: ID
  ) {
    editInspection(
    input: {
      id: $id, name: $name, periodCycle: $periodCycle, periodNumber: $periodNumber,
      dueDate: $dueDate, doneAt: $doneAt, notes: $notes, state: $state, result: $result,
      inspectionFields: $inspectionFields, inspectorId: $inspectorId
    }) {
      inspection {
        id
        name
      }
    }
  }
`;
