import { gql } from '@apollo/client';

export default gql`
  mutation editInspectionSubject($id: ID!, $name: String!, $serialNumber: String, $placeId: ID) {
    editInspectionSubject(input: { id: $id, name: $name, serialNumber: $serialNumber, placeId: $placeId}) {
      inspectionSubject {
        id
        name
      }
    }
  }
`;
