import React from 'react';
import { Button, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import PlanShow from 'components/user/plan/show';
import ShowItemRow from 'components/item/show_item_row';
import Boolean from 'components/boolean';
import Date from 'components/date';

const SubscriptionShow = ({ subscription }) => {
  return <WhiteCard>
    <ShowItemRow label={<FormattedMessage id='plan.subscription.active'/>}>
      <Boolean value={subscription.active}/>
    </ShowItemRow>
    <ShowItemRow label={<FormattedMessage id='plan.subscription.ended_at'/>}>
      <Date date={subscription.endDate}/>
    </ShowItemRow>
    <PlanShow plan={subscription.plan}/>
    <Box display='flex' justifyContent='flex-end'>
      <Button variant='contained' disabled={!subscription.active}>
        <FormattedMessage id='button.cancel'/>
      </Button>
    </Box>
  </WhiteCard>;
};

export default SubscriptionShow;
