import React, { useState } from 'react';
import { Drawer, Button, Box, Pagination, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { FilterProvider, useFilter, initTemplateFilterValues } from 'contexts/filter';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import { usePaginate } from 'contexts/paginate';
import FilterForm from 'components/template/filter_form';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Loader from 'components/loader';
import FilterTags from 'components/filter/tags';
import EmptyInfo from 'components/empty_info';
import CreateButton from 'components/template/button/create';
import TemplatesQuery from 'queries/template/index';
import Index from 'components/template/index';

const IndexContainer = () => {
  const { page, onPageChange } = usePaginate();
  const setError = useError();
  const { templatesFilter, setTemplatesFilter } = useFilter();
  const [filterVisible, setFilterVisible] = useState(false);
  const { data = { templates: { templates: [], perPage: 10 } }, loading } = useQuery(
    TemplatesQuery,
    {
      variables: {
        ...templatesFilter,
        page: page
      },
      onError: () => {
        setError('error.template.index');
      }
    }
  );

  return (
    <FilterProvider>
      {filterVisible &&
        <Drawer
          anchor='right'
          variant='persistent'
          open={filterVisible}
          onClose={() => { setFilterVisible(false);} }>
          <FilterForm
            initialValues={templatesFilter}
            defaultValues={initTemplateFilterValues}
            onSubmit={(values) => {
              onPageChange(1);
              setTemplatesFilter(values);
            }}
            onClose={() => { setFilterVisible(false);} }/>
        </Drawer>
      }
      {loading
        ? <Loader/>
        : <>
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <CreateButton />
              <Button
                onClick={() => setFilterVisible(!filterVisible)}
                variant='contained'
                color={JSON.stringify(initTemplateFilterValues) === JSON.stringify(templatesFilter) ? 'primary' : 'warning'}>
                <FormattedMessage id='button.filter'/> <FilterAltIcon/>
              </Button>
            </ButtonGroup>
          </Box>
          <FilterTags
            filterValues={templatesFilter}
            defaultValues={initTemplateFilterValues}
            onFilterChange={setTemplatesFilter}/>
          <EmptyInfo
            visible={!loading && data.templates.templatesTotal === 0}
            description={<FormattedMessage id='empty.templates.title'/>}/>
          <Index templates={data.templates}/>
          <Box display='flex' justifyContent='center'>
          {data.templates.templatesTotalPages> 1 &&
            <Pagination
              count={data.templates.templatesTotalPages}
              page={page}
              onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
          }
          </Box>
        </>
      }
      <DividerContainer/>
    </FilterProvider>
  );
};

export default IndexContainer;
