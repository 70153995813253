import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import Edit from 'components/user/profile/modal/edit';

const EditButton = ({ user, refetch }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button variant='contained' onClick={() => (addModal(
      <Edit
        user={user}
        onSuccess={() => {
          refetch();
          removeModal();
          setInfo(intl.formatMessage({ id: 'info.user.profile.edited' }));
        }}
        handleClose={() => {
        removeModal();
      }}/>
    ))}>
      <FormattedMessage id='button.edit'/>
    </Button>

  );
};

export default EditButton;
