import { gql } from '@apollo/client';

export default gql`
  mutation editPlace($id: ID!, $name: String!, $street: String!, $city: String!, $zipCode: String!) {
    editPlace(input: { id: $id, name: $name, street: $street, city: $city, zipCode: $zipCode }) {
      place {
        id
      },
    }
  }
`;
