import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import Edit from 'components/inspection/modal/edit';

const EditButton = ({ inspection, refetch}) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button
      disabled={!inspection.adminRole}
      onClick={() => { addModal(
        <Edit
          inspection={inspection}
          onSuccess={() => {
            refetch();
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.inspection.edited' }));
          }}
          handleClose={() => removeModal()}/>
      ); }}>
      <FormattedMessage id='button.edit'/>
    </Button>
  );
};

export default EditButton;
