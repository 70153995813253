import React from 'react';
import { List } from '@mui/material';
import ListItem from 'components/assigned_users/index/list_item';
import ListHeader from 'components/assigned_users/index/list_header';

const Index = ({ place, refetch }) => {
  return (
    <List>
      <ListHeader visible={place.spaceUserRoles.length> 0}/>
      {place.spaceUserRoles.map((spaceUserRole) => (
        <ListItem
          key={spaceUserRole.spaceUser.id}
          spaceUser={spaceUserRole.spaceUser}
          role={spaceUserRole.name}
          placeId={place.id}
          refetch={refetch} />
      ))}
    </List>
  );
};

export default Index;
