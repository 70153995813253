class InspectionResult {
  SUCCESS = 100;
  FAILURE = 200;

  constructor(inspectionResult) {
    this.inspectionResult = inspectionResult;
  }

  get color() {
    switch (this.inspectionResult) {
      case this.FAILURE:
        return 'error';
      case this.SUCCESS:
        return 'success';
      default:
        return 'error';
    }
  }

  static create(inspection) {
    return new InspectionResult(inspection);
  }
}

const InspectionResultFactory = (inspectionResult) => new InspectionResult(inspectionResult);

export default InspectionResultFactory;
