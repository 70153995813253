import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import BreadcrumbContainer from 'components/breadcrumb_container';
import IndexContainer from 'components/inspection_subject/index_container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { PaginateProvider } from 'contexts/paginate';

const InspectionSubjects = () => {
  return (
    <PaginateProvider>
      <ContentContainer maxWidth='xl'>
        <BreadcrumbContainer>
          <BreadCrumbTypography><FormattedMessage id='breadcrumb.inspection_subjects.index'/></BreadCrumbTypography>
        </BreadcrumbContainer>
        <IndexContainer />
      </ContentContainer>
    </PaginateProvider>
  );
};

export default InspectionSubjects;
