import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { endOfWeek, startOfWeek, startOfMonth, endOfMonth } from 'date-fns';

const PredefinedFilters = ({ onClick, initialValues }) => {
  const setDueDateThisWeek = () => {
    onClick({ ...initialValues, dueDateFrom: startOfWeek(new Date()), dueDateTo: endOfWeek(new Date()), state: 0 });
  };

  const setDueDateThisMonth = () => {
    onClick({ ...initialValues, dueDateFrom: startOfMonth(new Date()), dueDateTo: endOfMonth(new Date()), state: 0 });
  };

  const setDoneAtLastWeek = () => {
    onClick({ ...initialValues, doneAtFrom: startOfWeek(new Date()), doneAtTo: endOfWeek(new Date()), state: 1 });
  };

  const setDoneAtLastMonth = () => {
    onClick({ ...initialValues, doneAtFrom: startOfMonth(new Date()), doneAtTo: endOfMonth(new Date()), state: 1 });
  };

  return (
    <ButtonGroup
      fullWidth
      orientation='vertical'>
      <Button onClick={setDueDateThisWeek} variant='text' >
        <FormattedMessage id='filter.to_do_this_week'/>
      </Button>
      <Button onClick={setDueDateThisMonth} variant='text'>
        <FormattedMessage id='filter.to_do_this_month'/>
      </Button>
      <Button onClick={setDoneAtLastWeek} variant='text'>
        <FormattedMessage id='filter.done_last_week'/>
      </Button>
      <Button onClick={setDoneAtLastMonth} variant='text'>
        <FormattedMessage id='filter.done_last_month'/>
      </Button>
    </ButtonGroup>
  );
};

export default PredefinedFilters;
