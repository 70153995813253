import React from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import { ChapterTypography } from 'components/styles_components/typography';
import Modal from 'components/modal';
import logo from 'assets/images/logo.png';

const Error = ({error, handleClose}) => {
  return <Modal handleClose={handleClose}>
    <ContentContainer maxWidth='xl'>
      <Grid container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <ChapterTypography variant='h6' align='center'>
          <FormattedMessage id='header.error.index'/>
        </ChapterTypography>
          <img src={logo} alt='Logo' width={100}/>
        <ChapterTypography variant='h6' align='center'>
          <FormattedMessage id={error}/>
        </ChapterTypography>
      </Grid>
    </ContentContainer>
  </Modal>;
};

export default Error;
