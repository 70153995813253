
import { gql } from '@apollo/client';
import InspectionFields from 'queries/inspection/inspection_fields_fragment';

export default gql`
  query inspection($id: ID!) {
    inspection(id: $id) {
      ...InspectionFields
      adminRole
      inspectorRole
      ownerRole
      notes
      relatedInspectionsTotal
      inspector {
        id
        name
        surname
        email
        confirmed
      }
      nextInspection {
        id
        name
        place {
          id
        }
        inspectionSubject {
          id
        }
      }
      previousInspection {
        id
        name
        place {
          id
        }
        inspectionSubject {
          id
        }
      }
      inspectionFields {
        id
        name
        value
        templateFieldId
      }
    }
  }
  ${InspectionFields}
`;

