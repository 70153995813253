import React from 'react';
import { format } from 'date-fns';
import { Box } from '@mui/material';

const FormattedDate = ({ date, warning=false, error=false }) => {
  if (!date) { return null; }

  const parsedDate = new Date(date);
  return (
    <Box
      sx={{ display: 'inline' }}
      color={error ? 'error.main' : warning ? 'warning.main' : 'text.primary' } >
      {format(parsedDate, 'dd-MM-yyyy')}
    </Box>
  );

};

export default FormattedDate;
