import React from 'react';
import { Formik } from 'formik';
import { Box, Button } from '@mui/material';
import { WideForm } from 'components/styles_components/form';
import { FormattedMessage, useIntl } from 'react-intl';
import UserAutocomplete from 'components/form/autocomplete/user';
import validationSchema from 'validation/place/permission_create';
import RoleSelect from 'components/form/role_select';

const Form = ({ initialValues, processing, onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(intl)}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <WideForm onSubmit={formik.handleSubmit}>
            <UserAutocomplete
              margin='dense'
              id='spaceUserId'
              name='spaceUserId'
              setValue={formik.setFieldValue}
              label={intl.formatMessage({ id: 'inspector' })}
              value={formik.values.spaceUserId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.spaceUserId && Boolean(formik.errors.spaceUserId) || undefined}
              helperText={formik.touched.spaceUserId && formik.errors.spaceUserId} />
            <RoleSelect
              margin='dense'
              id='role'
              name='role'
              label={intl.formatMessage({ id: 'place.role' })}
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.role && Boolean(formik.errors.role) || undefined}
              helperText={formik.touched.role && formik.errors.role} />
            <Button
              fullWidth
              className='margin-top-10'
              disabled={!formik.isValid || processing}
              color='primary'
              variant='contained'
              type='submit'>
              <FormattedMessage id='button.save' />
            </Button>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                size='small'
                disabled={processing}
                onClick={onCancel}>
                <FormattedMessage id='button.cancel' />
              </Button>
            </Box>
          </WideForm>
        );
      }}
    </Formik>
  );
};

export default Form;
