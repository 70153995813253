class Inspection {
  constructor(inspection) {
    this.inspection = inspection;
  }

  get htmlClass() {
    return this.inspection.overdue ? 'overdue' : this.inspection.incoming ? 'incoming' : '';
  }

  static create(inspection) {
    return new Inspection(inspection);
  }
}

const InspectionFactory = (inspection) => new Inspection(inspection);

export default InspectionFactory;
