import React from 'react';
import { FormattedMessage } from 'react-intl';

const Boolean = ({ value }) => {
  if (value) { return <FormattedMessage id='boolean.yes'/>; }

  return <FormattedMessage id='boolean.no'/>;
};

export default Boolean;
