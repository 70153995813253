import React from 'react';
import { Chip, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TagGrid } from 'components/styles_components/container';
import Date from 'components/date';

const FilterTags = ({ defaultValues, filterValues, onFilterChange }) => {
  const intlKey = {
    name: 'name',
    email: 'email',
    serialNumber: 'inspection_subject.serial_number',
    state: 'inspection.state',
    dueDateFrom: 'filter.due_date_from',
    dueDateTo: 'filter.due_date_to',
    doneAtFrom: 'filter.done_at_from',
    doneAtTo: 'filter.done_at_to',
    canceledAtFrom: 'filter.canceled_at_from',
    canceledAtTo: 'filter.canceled_at_to',
  };

  const valueParser = {
    name: (value) => value,
    email: (value) => value,
    serialNumber: (value) => value,
    state: (value) => <FormattedMessage id={`inspection.state.${value}`}/>,
    dueDateFrom: (value) => <Date date={value}/>,
    dueDateTo: (value) => <Date date={value}/>,
    doneAtFrom: (value) => <Date date={value}/>,
    doneAtTo: (value) => <Date date={value}/>,
    canceledAtFrom: (value) => <Date date={value}/>,
    canceledAtTo: (value) => <Date date={value}/>,
  };

  const deleteKey = (key) => {
    const newFilterValues = { ...filterValues };
    newFilterValues[key] = defaultValues[key];
    onFilterChange(newFilterValues);
  };

  return (
    <TagGrid container spacing={2}>
    { Object.keys(filterValues).map((key, index) => {
      if (filterValues[key] === defaultValues[key]) {
        return null;
      }
      return (
        <Grid item key={index}>
          <Chip
            label={<><FormattedMessage id={intlKey[key]}/>: <b>{valueParser[key](filterValues[key])}</b></>}
            onDelete={() => {deleteKey(key);}}/>
        </Grid>
      );
    })}
    </TagGrid>
  );
};

export default FilterTags;
