import React from 'react';
import { List } from '@mui/material';
import ListHeader from 'components/inspection/index/list_header';
import ListItem from 'components/inspection/index/list_item';

const Index = ({ inspections }) => {
  return (
    <List>
      <ListHeader visible={inspections.length> 0}/>
      {inspections.map((inspection) => (
        <ListItem key={inspection.id} inspection={inspection} />
      ))}
    </List>
  );
};

export default Index;
