import React from 'react';
import { Grid } from '@mui/material';
import { Footer as StyledFooter } from 'components/styles_components/footer';
import { FormattedMessage } from 'react-intl';

// TODO
const Footer = () => {
  return (
    <StyledFooter className='footer'>
      {/* <Grid
        container
        justifyContent='center'
        textAlign='center'
        alignItems='center'>
          <Grid xs={12} md={6} lg={4} item>
            HELP SECTION
          </Grid>
          <Grid xs={12} md={6} lg={4} item>
            Contact Section
          </Grid>
          <Grid xs={12} md={6} lg={4} item>
            Social media section
          </Grid>
      </Grid> */}
      <Grid
        container
        justifyContent='center'
        alignItems='center'>
        <FormattedMessage id='footer.title'/>
      </Grid>

    </StyledFooter>
  );
};

export default Footer;

