import React from 'react';
import { List } from '@mui/material';
import ListItem from 'components/template/index/list_item';
import ListHeader from 'components/template/index/list_header';

const Index = ({ templates }) => {
  return (
    <List>
      <ListHeader visible={templates.templates.length> 0}/>
      {templates.templates.map((template) => <ListItem key={template.id} template={template}/>)}
    </List>
  );
};

export default Index;
