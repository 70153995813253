
import * as yup from 'yup';

const validationSchema = (intl) => yup.object().shape({
  name: yup
    .string()
    .required(intl.formatMessage({ id: 'validation.required' })),
  templateFields: yup.array().of(
    yup.object().shape({
      name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),
    })
  ),
});

export default validationSchema;
