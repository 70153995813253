import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IndexListItemButton } from 'components/styles_components/list';
import { UserLink } from 'utils/link';
import Boolean from 'components/boolean';

const ListItem = ({ spaceUser }) => {
  const navigate = useNavigate();

  return (
    <IndexListItemButton
      onClick={() => {
        navigate(UserLink(spaceUser.id));
      }}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={5}>
          <Typography>
            {spaceUser.email}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {spaceUser.name} {spaceUser.surname}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign='right'>
          <Typography>
            <Boolean value={spaceUser.confirmed}/>
          </Typography>
          </Grid>
      </Grid>
    </IndexListItemButton>
  );
};

export default ListItem;
