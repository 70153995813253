import React from 'react';
import Cookies from 'js-cookie';
import { useError } from 'contexts/error';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack, TextField, Button } from '@mui/material';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Form } from 'components/styles_components/form';
import { ChapterTypography } from 'components/styles_components/typography';
import { RegisterLink, LoginLink } from 'utils/link';
import { StyledLink } from 'components/styles_components/link';
import ConfirmMutation from 'mutations/user/confirm';
import validationSchema from 'validation/user/confirmation';

const LoginForm = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const setError = useError();
  const intl = useIntl();

  const [confirmMutation, { loading }] = useMutation(ConfirmMutation, {
    onCompleted: ({ confirmUser }) => {
      Cookies.set('authenticated', confirmUser.token, { expires: confirmUser.expiresIn });
      navigate(0);
    },
    onError: () => {
      setError('error.user.confirm');
    },
  });

  return (
    <Formik
      initialValues={{ email: '', token }}
      validationSchema={validationSchema(intl)}
      onSubmit={(values) => confirmMutation({variables: values})}>
      {({ errors, touched, handleSubmit, handleChange, handleBlur, values }) => (
        <Stack>
          <ChapterTypography variant='h6' align='center'>
            <FormattedMessage id='header.user.confirm'/>
          </ChapterTypography>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin='dense'
                id='email'
                name='email'
                label={intl.formatMessage({ id: 'user.email' })}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email) || undefined}
                helperText={touched.email && errors.email}/>
              <Button
                fullWidth
                disabled={loading}
                color='primary'
                variant='contained'
                type='submit'>
                <FormattedMessage id='button.login'/>
              </Button>
              <Grid
                container
                direction='row'
                justifyContent='space-between'>
                <StyledLink to={RegisterLink()}>
                  <FormattedMessage id='button.register'/>
                </StyledLink>
                <StyledLink to={LoginLink()}>
                  <FormattedMessage id='button.login'/>
                </StyledLink>
              </Grid>
            </Form>
          </Grid>
        </Stack>
      )}
    </Formik>
  );
};

export default LoginForm;
