
import * as yup from 'yup';

const validationSchema = (intl, existedNames) => yup.object().shape({
  fieldName: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' }))
    .test('is-unique', intl.formatMessage({ id: 'validation.role.unique' }), (value) => {
      return !existedNames.includes(value);
    }
  ),
});

export default validationSchema;
