import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListItemButton, ListItem, TextField } from '@mui/material';
import validationSchema from 'validation/inspection_field/create';

const New = ({ onSubmit, existedNames = [], disabled }) => {
  const intl = useIntl();
  const [fieldName, setFieldName] = useState('');
  const [errors, setErrors] = useState({ name: '' });
  const validateFieldName = async () => {
    try {
      await validationSchema(intl, existedNames).validate({ fieldName });
      setErrors({ fieldName: '' });
      return true;
    } catch (error) {
      setErrors({ fieldName: error.message });
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ fieldName: '' });

    if (name === 'fieldName') {
      setFieldName(value);
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateFieldName();

    if (isValid) {
      onSubmit({ fieldName });
      setFieldName('');
    }
  };
  return (
    <ListItem
      disableGutters
      style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        fullWidth
        id='newFieldName'
        name='fieldName'
        type='text'
        label={intl.formatMessage({ id: 'inspection.field.name' })}
        value={fieldName}
        onChange={handleChange}
        disabled={disabled}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            const isValid = await validateFieldName();
            if (isValid) {
              handleSubmit(e);
            }
            if (fieldName) {
              e.preventDefault();
            }
          }
        }}
        onBlur={validateFieldName}
        error={!!fieldName && !!errors.fieldName} />
      <ListItemButton
        color='primary'
        variant='contained'
        onClick={handleSubmit}
        disabled={!fieldName || !!errors.fieldName || disabled}>
        <FormattedMessage id='button.add' />
      </ListItemButton>
    </ListItem>
  );
};

export default New;
