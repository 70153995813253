
import { gql } from '@apollo/client';

export default gql`
  query plans {
    user {
      activePlan {
        id
      }
    }
    plans {
      plansTotal
      plans {
        id
        name
        price
        billingInterval
        placesLimit
        inspectionSubjectsLimit
        inspectionsLimit
      }
    }
  }
`;

