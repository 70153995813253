import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FilterProvider } from 'contexts/filter';
import { ErrorProvider } from 'contexts/error';
import { InfoProvider } from 'contexts/info';
import { ModalProvider } from 'contexts/modal';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IntlWrapper from 'components/wrappers/intl';
import ApolloWrapper from 'components/wrappers/apollo';
import PublicRoute from 'components/route/public';
import PrivateRoute from 'components/route/private';
import theme from 'theme';
import Dashboard from 'pages/dashboard';
import UserLogin from 'pages/user/login';
import UserRegister from 'pages/user/register';
import UserForgotPassword from 'pages/user/forgot_password';
import UserConfirmation from 'pages/user/confirmation';
import UserResetPassword from 'pages/user/reset_password';
import Profile from 'pages/user/profile';
import Inspections from 'pages/inspections';
import InspectorInspections from 'pages/inspector_inspections';
import InspectionSubjects from 'pages/inspection_subjects';
import Places from 'pages/places';
import PlacesShow from 'pages/places/show';
import InspectionSubject from 'pages/inspection_subjects/show';
import Inspection from 'pages/inspections/show';
import InspectorInspection from 'pages/inspector_inspections/show';
import Templates from 'pages/templates';
import Template from 'pages/templates/show';
import Users from 'pages/users';
import User from 'pages/users/show';
import UserInvitationConfirmation from 'pages/users/invitation_confirmation';

const App = () => {
  console.log("ENVS");
  console.log(process.env.REACT_APP_GRAPHQL_SERVER_URL);
  console.log(process.env);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <IntlWrapper locale='pl'>
          {/* eslint-disable-next-line no-undef */}
          <ApolloWrapper apiUrl={process.env.REACT_APP_GRAPHQL_SERVER_URL}>
            <FilterProvider>
              <ErrorProvider>
                <InfoProvider>
                  <BrowserRouter>
                    <ModalProvider>
                      <Routes>
                        <Route element={<PublicRoute />}>
                          <Route path='/users/login' element={<UserLogin />} />
                          <Route path='/users/register' element={<UserRegister />} />
                          <Route path='/users/forgot_password' element={<UserForgotPassword />} />
                          <Route path='/users/reset_password/:token' element={<UserResetPassword />} />
                          <Route path='/users/confirm/:token' element={<UserConfirmation />} />
                        </Route>
                        <Route element={<PrivateRoute />}>
                          <Route path='/' element={<Dashboard />} />
                          <Route path='/inspections' element={<Inspections />} />
                          <Route path='/inspections/:inspectionId' element={<Inspection />} />
                          <Route path='/inspector_inspections' element={<InspectorInspections />} />
                          <Route path='/inspector_inspections/:inspectionId' element={<InspectorInspection />} />
                          <Route path='/inspection_subjects' element={<InspectionSubjects />} />
                          <Route path='/inspection_subjects/:inspectionSubjectId' element={<InspectionSubject />} />
                          <Route path='/places' element={<Places />} />
                          <Route path='/places/:id' element={<PlacesShow />}/>
                          <Route path='/users/profile' element={<Profile />} />
                          <Route path='/templates' element={<Templates />} />
                          <Route path='/templates/:id' element={<Template />} />
                          <Route path='/users' element={<Users />} />
                          <Route path='/users/invitation/confirm/:token' element={<UserInvitationConfirmation />} />
                          <Route path='/users/:id' element={<User />} />
                        </Route>
                      </Routes>
                      </ModalProvider>
                  </BrowserRouter>
                </InfoProvider>
              </ErrorProvider>
            </FilterProvider>
          </ApolloWrapper>
        </IntlWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
