import React, { useEffect, useDeferredValue } from 'react';
import { Grid, Box, Pagination } from '@mui/material';
import { useQuery } from '@apollo/client';
import { usePaginate } from 'contexts/state_paginate';
import { FormattedMessage } from 'react-intl';
import { DayHeader, DayContent } from 'components/styles_components/calendar';
import Date from 'components/date';
import InspectionsQuery from 'queries/inspection/index';
import InspectorInspectionsQuery from 'queries/inspection/inspector_index';
import Index from 'components/inspection/index';
import InspectorIndex from 'components/inspector_inspection/index';

const DayItem = ({ date, query }) => {
  const { page, onPageChange } = usePaginate();
  const { loading, data={ inspections: { inspections: [], inspectionsTotalPages: 0, inspectionsTotal: 0 }, inspectorInspections: { inspections: [], inspectionsTotalPages: 0, inspectionsTotal: 0 } } } = useQuery(
    query === 'admin' ? InspectionsQuery : InspectorInspectionsQuery,
    {
      variables: {
      page: page,
      dueDateFrom: date,
      dueDateTo: date
    },
    notifyOnNetworkStatusChange: true
  });
  const deferredQuery = useDeferredValue(query);


  useEffect(() => {
    if (deferredQuery !== query) {
      onPageChange(1);
    }
  }, [query]);

  const inspectionsData = query === 'admin' ? data.inspections : data.inspectorInspections;

  return (
    <Grid container>
      <Grid item xs={12}>
        <DayHeader className={inspectionsData.inspectionsTotal === 0 ? 'empty' : ''}>
          <Grid container direction='row' spacing={2}>
            <Grid item>
              <Date date={date} />
            </Grid>

            {!loading && inspectionsData.inspectionsTotal === 0 &&
              <Grid item>
                <FormattedMessage id='empty.inspections.title.short'/>
              </Grid>
            }
          </Grid>
        </DayHeader>
      </Grid>
      {inspectionsData.inspectionsTotal > 0 &&
        <Grid item xs={12}>
          <DayContent>
            {
              query === 'admin'
              ? <Index inspections={inspectionsData.inspections} />
              : <InspectorIndex inspections={inspectionsData.inspections} />
            }
            <Box display='flex' justifyContent='center'>
              {inspectionsData.inspectionsTotalPages> 1 &&
                <Pagination
                  count={inspectionsData.inspectionsTotalPages}
                  page={page}
                  onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
              }
            </Box>
          </DayContent>
        </Grid>
      }
    </Grid>
  );
};

export default DayItem;
