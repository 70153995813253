import { gql } from '@apollo/client';

export default gql`
  mutation editUser($name: String, $surname: String!, $sendPushNotificationsSetting: Boolean, $sendEmailNotificationsSetting: Boolean) {
    editUser(input: { name: $name, surname: $surname, sendPushNotificationsSetting: $sendPushNotificationsSetting, sendEmailNotificationsSetting: $sendEmailNotificationsSetting }) {
      user {
        id
        email
      }
    }
  }
`;
