import { gql } from '@apollo/client';

export default gql`
  mutation doneInspection($id: ID!, $result: Int, $notes: String, $doneAt: ISO8601DateTime, $dueDate: ISO8601DateTime, $inspectionFields: [InspectionFieldExecutionInput!]) {
    doneInspection(input: { id: $id, result: $result, notes: $notes, doneAt: $doneAt, dueDate: $dueDate, inspectionFields: $inspectionFields }) {
      inspection {
        id
      }
    }
  }
`;
