class Place {
  constructor(place) {
    this.place = place;
  }

  get htmlClass() {
    return this.place.overdueInspectionsTotal ? 'overdue' : this.place.incomingInspectionsTotal ? 'incoming' : '';
  }

  get incomingInspectionsColor() {
    return this.place.incomingInspectionsTotal > 0 ? 'warning' : 'info';
  }

  get overdueInspectionsColor() {
    return this.place.overdueInspectionsTotal > 0 ? 'error' : 'info';
  }

  get hasInspectionSubjects() {
    return this.place.inspectionSubjectsTotal > 0;
  }

  get hasInspections() {
    return this.place.inspectionsTotal > 0;
  }

  static create(place) {
    return new Place(place);
  }
}

const PlaceFactory = (place) => new Place(place);

export default PlaceFactory;
