import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import BaseEmptyInfo from 'components/empty_info';
import CreateInspectionSubjectButton from 'components/inspection_subject/button/create';

const EmptyInfo = ({ visible, placeId }) => {
  if (visible) {
    return (
      <WhiteCard>
        <BaseEmptyInfo
          visible={true}
          description={<FormattedMessage id='empty.inspection_subjects.title'/>}>
            <CreateInspectionSubjectButton placeId={placeId} />
        </BaseEmptyInfo>
      </WhiteCard>
    );
  } else {
    return null;
  }
};

export default EmptyInfo;
