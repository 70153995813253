import React from 'react';
import { CeneteredBox } from 'components/styles_components/container';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <CeneteredBox>
      <CircularProgress/>
    </CeneteredBox>
  );
};

export default Loader;
