import React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import { useError } from 'contexts/error';
import EditMutation from 'mutations/inspection/edit';
import Form from 'components/inspection/modal/edit/edit_form';

const Edit = ({ handleClose, onSuccess, inspection }) => {
  const setError = useError();
  const [editMutation, { loading }] = useMutation(EditMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.inspection.edit');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.inspection.edit'/>
      </ModalTypography>
      <Form
        initialValues={{
          id: inspection.id,
          name: inspection.name,
          placeId: inspection.place.id,
          inspectorId: inspection.inspector && inspection.inspector.id,
          inspectionSubjectId: inspection.inspectionSubject.id,
          state: inspection.state,
          result: inspection.result,
          periodNumber: inspection.periodNumber,
          periodCycle: inspection.periodCycle,
          dueDate: inspection.dueDate && new Date(inspection.dueDate),
          doneAt: inspection.doneAt && new Date(inspection.doneAt),
          inspectionFields: inspection.inspectionFields.map(field => ({
            id: field.id,
            name: field.name,
            value: field.value,
            templateFieldId: field.templateFieldId,
          })),
          notes: inspection.notes || undefined,
        }}
        processing={loading}
        onSubmit={(values) => editMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Edit;
