import React from 'react';
import { Grid, Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import { FieldsList, FieldsListItem } from 'components/styles_components/list';
import EditButton from 'components/template/button/edit';
import DeleteButton from 'components/template/button/delete';
import ShowItemRow from 'components/item/show_item_row';

const ShowItem = ({ template, refetch }) => {
  return (
    <WhiteCard>
      <Grid container
        direction='row'
        justifyContent='space-between'
        spacing={2}
        alignItems='center'>
        <ShowItemRow label={<FormattedMessage id='name'/>}>
          {template.name}
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection.period'/>}>
          {template.perdiodCycle && template.periodNumber ? <>
            {template.periodNumber} <FormattedMessage id={`inspection.period.${template.periodCycle}`}/>
            </> : '-'
          }
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection.field.count'/>}>
          <FieldsList>
            {template.templateFields.map((field) => {
              return (
                <FieldsListItem key={`template-${field.id}`}>{field.name}</FieldsListItem>
              );
            })}
          </FieldsList>
        </ShowItemRow>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <EditButton
              template={template}
              refetch={refetch} />
            <DeleteButton
              template={template} />
          </ButtonGroup>
        </Box>
      </Grid>
    </WhiteCard>
  );
};

export default ShowItem;
