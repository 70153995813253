import React from 'react';
import { useMutation } from '@apollo/client';
import { ModalTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';
import { useError } from 'contexts/error';
import CreateMutation from 'mutations/place/create';
import Form from 'components/place/form';

const Create = ({ handleClose, onSuccess, name='' }) => {
  const setError = useError();
  const [createMutation, { loading }] = useMutation(CreateMutation, {
    onCompleted: ({ createPlace }) => {
      onSuccess(createPlace.place);
    },
    onError: () => {
      setError('error.place.create');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.place.create'/>
      </ModalTypography>
      <Form
        initialValues={{
          name: name,
          street: '',
          zipCode: '',
          city: '',
        }}
        processing={loading}
        onSubmit={(values) => createMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Create;
