import * as yup from 'yup';

const validationSchema = () => yup.object().shape({
  name: yup
    .string(''),
  surname: yup
    .string('')
});

export default validationSchema;
