import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, TextField, Box, Button } from '@mui/material';
import { Form as StyledForm } from 'components/styles_components/form';
import validationSchema from 'validation/place/create';

const Form = ({ onSubmit, onCancel, processing, initialValues }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(intl)}
      onSubmit={onSubmit}>
      {formik => (
        <StyledForm onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin='dense'
            id='name'
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name) || undefined}
            helperText={formik.touched.name && formik.errors.name}/>
          <TextField
            fullWidth
            margin='dense'
            id='street'
            name='street'
            label={intl.formatMessage({ id: 'place.street' })}
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.street && Boolean(formik.errors.street) || undefined}
            helperText={formik.touched.street && formik.errors.street}/>
          <Grid container>
            <Grid item xs={6} md={3}>
              <TextField
                margin='dense'
                id='zipCode'
                name='zipCode'
                label={intl.formatMessage({ id: 'place.zip_code' })}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode) || undefined}
                helperText={formik.touched.zipCode && formik.errors.zipCode}/>
            </Grid>
            <Grid item xs={6} md={9}>
              <TextField
                fullWidth
                margin='dense'
                id='city'
                name='city'
                label={intl.formatMessage({ id: 'place.city' })}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city) || undefined}
                helperText={formik.touched.city && formik.errors.city}/>
            </Grid>
          </Grid>
          <Button
            className='margin-top-10'
            fullWidth
            disabled={!formik.isValid || processing || !formik.dirty}
            color='primary'
            variant='contained'
            type='submit'>
            <FormattedMessage id='button.save'/>
          </Button>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              disabled={processing}
              onClick={onCancel}>
              <FormattedMessage id='button.cancel'/>
            </Button>
          </Box>
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
