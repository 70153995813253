import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import Cancel from 'components/inspection/modal/cancel';
import InspectionState from 'utils/inspection_state';

const CancelButton = ({ inspection, refetch }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();

  return (
    <Button
      disabled={inspection.state !== InspectionState().PENDING || !inspection.adminRole}
      onClick={() => { addModal(
        <Cancel
          inspection={inspection}
          handleClose={() => removeModal()}
          onSuccess={() => {
            refetch();
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.inspection.canceled' }));
          }} />
      ); }}>
      <FormattedMessage id='button.cancel'/>
    </Button>
  );
};

export default CancelButton;
