
import { gql } from '@apollo/client';

export default gql`
  query place($id: ID!) {
    place(id: $id) {
      id
      name
      street
      city
      adminRole
      ownerRole
      canAddInspection
      canAddInspectionSubject
      zipCode
      inspectionSubjectsTotal
      inspectionsTotal
      incomingInspectionsTotal
      overdueInspectionsTotal
    }
  }
`;

