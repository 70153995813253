import styled from 'styled-components';
import { Grid } from '@mui/material';
import theme from 'theme';

export const Form = styled.form`
  margin-bottom: 20px;
`;

export const WideForm = styled.form`
  margin-bottom: 20px;
  min-width: 50vh;
  max-width: ${theme.width.form};
`;

export const DrawerForm = styled.form`
  width: ${theme.width.filterDrawer};
  padding: 0 20px;
  margin: 20px auto;
`;

export const WideFormGrid = styled(Grid)`
  max-width: ${theme.width.form};
`;
