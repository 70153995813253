
import * as yup from 'yup';

const validationSchema = () => yup.object({
  note: yup
    .string(''),
    canceledAt: yup
    .date()
    .nullable(),
  });

export default validationSchema;
