import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { useInfo } from 'contexts/info';
import { useError } from 'contexts/error';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TemplatesLink } from 'utils/link';
import DeleteMutation from 'mutations/template/delete';
import Confimation from 'components/form/confirmation';

const DeleteButton = ({ template }) => {
  const setInfo = useInfo();
  const setError = useError();
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteMutation, deleteMutationData] = useMutation(DeleteMutation, {
    onCompleted: () => {
      setInfo(intl.formatMessage({ id: 'info.template.deleted' }));
      navigate(TemplatesLink());
    },
    onError: () => {
      setError('error.template.delete');
    },
  });

  return (
    <Confimation
      title={<FormattedMessage id='header.template.delete'/>}
      description={<FormattedMessage id='confirmation.template.delete'/>}
      onClick={() => {
        deleteMutation({ variables: { id: template.id }});
      }}>
      <Button
        color='error'
        disabled={deleteMutationData.loading}>
        <FormattedMessage id='button.delete'/>
      </Button>
    </Confimation>
  );
};

export default DeleteButton;
