import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { PaginateProvider } from 'contexts/paginate';
import IndexContainer from 'components/inspector_inspection/index_container';
import BreadcrumbContainer from 'components/breadcrumb_container';

const Inspections = () => {

  return (
    <PaginateProvider>
      <ContentContainer maxWidth='xl'>
        <BreadcrumbContainer>
          <BreadCrumbTypography><FormattedMessage id='breadcrumb.inspector_inspections.index'/></BreadCrumbTypography>
        </BreadcrumbContainer>
        <IndexContainer />
      </ContentContainer>
    </PaginateProvider>
  );
};

export default Inspections;
