import React from 'react';
import { Grid, Typography } from '@mui/material';
import { IndexListItem } from 'components/styles_components/list';
import { UserLink } from 'utils/link';
import { FormattedMessage } from 'react-intl';
import DeleteButton from 'components/assigned_users/button/delete';
import { StyledLink } from 'components/styles_components/link';

const ListItem = ({ spaceUser, role, refetch, placeId }) => {
  return (
    <IndexListItem>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={3}>
          <StyledLink to={UserLink(spaceUser.id)}>
            {spaceUser.email}
          </StyledLink>
        </Grid>
        <Grid item xs={3} textAlign='left'>
          <Typography>
            {spaceUser.name} {spaceUser.surname}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign='left'>
          <Typography>
              <FormattedMessage id={`place.role.${role}`}/>
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign='right'>
          <DeleteButton onSuccess={refetch} placeId={placeId} spaceUserId={spaceUser.id}/>
        </Grid>
      </Grid>
    </IndexListItem>
  );
};

export default ListItem;
