
import * as yup from 'yup';

const validationSchema = (intl) => yup.object({
  name: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  placeId: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  inspectionSubjectId: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  periodNumber: yup
    .number()
    .required(intl.formatMessage({ id: 'validation.required' }))
    .min(1, intl.formatMessage({ id: 'validation.periodNumber' })),
  periodCycle: yup
    .string('')
    .required(intl.formatMessage({ id: 'validation.required' })),
  dueDate: yup
    .date()
    .nullable(),
  doneAt: yup
    .date()
    .nullable(),
  notes: yup
    .string()
    .nullable(),
});

export default validationSchema;
