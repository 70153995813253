import { gql } from '@apollo/client';

export default gql`
  mutation createTemplate($name: String!, $templateFields: [TemplateFieldInput!]!) {
    createTemplate(input: { name: $name, templateFields: $templateFields }) {
      template {
        id
        name
      },
    }
  }
`;
