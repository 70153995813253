
import { gql } from '@apollo/client';

export default gql`
  query templates($page: Int, $name: String) {
    templates(page: $page, name: $name) {
      templatesTotalPages
      templatesTotal
      perPage
      hasNextPage
      templates {
        id
        name
      }
    }
  }
`;

