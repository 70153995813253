import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { PaginateProvider } from 'contexts/state_paginate';
import { useSearchParams } from 'react-router-dom';
import Form from 'components/dashboard/calendar/form';
import DayItem from 'components/dashboard/calendar/day_item';

const Calendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const begginingOfWeek = () => {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const endOfWeek = () => {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff + 7));
  };

  const [startDate, setStartDate] = useState(() => new Date(searchParams.get('dueDateFrom') || begginingOfWeek()));
  const [endDate, setEndDate] = useState(() => new Date(searchParams.get('dueDateTo') || endOfWeek()));
  const [query, setQuery] = useState(searchParams.get('query') || 'admin');

  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const onSubmit = (values) => {
    setSearchParams(values);
    setStartDate(values.dueDateFrom);
    setEndDate(values.dueDateTo);
    setQuery(values.query);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form initialValues={{
          dueDateFrom: startDate,
          dueDateTo: endDate,
          query: query
        }}
        onSubmit={onSubmit}/>
        {getDatesBetween(startDate, endDate).map((date) => (
          <PaginateProvider key={date}>
            <DayItem
              query={query}
              date={date.toISOString()}/>
          </PaginateProvider>
        ))}
      </Grid>
    </Grid>
  );
};

export default Calendar;
