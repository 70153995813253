import { gql } from '@apollo/client';

export default gql`
  mutation createInspection(
    $name: String!, $inspectionSubjectId: ID!, $periodCycle: String!, $periodNumber: Int!,
    $dueDate: ISO8601DateTime, $doneAt: ISO8601DateTime, $notes: String,
    $templateId: ID, $inspectionFields: [InspectionFieldInput!]
  ) {
    createInspection(input: {
      name: $name, inspectionSubjectId: $inspectionSubjectId, periodCycle: $periodCycle,
      periodNumber: $periodNumber, dueDate: $dueDate, doneAt: $doneAt, notes: $notes,
      templateId: $templateId, inspectionFields: $inspectionFields
    }) {
      inspection {
        id
        name
        inspectionSubject {
          id
        }
        place {
          id
        }
      }
    }
  }
`;
