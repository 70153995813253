import { gql } from '@apollo/client';

export default gql`
  mutation createUser($email: String!, $password: String!) {
    createUser(input: { email: $email, password: $password }) {
      user {
        id
        email
      }
    }
  }
`;