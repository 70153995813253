import React, { createContext, useContext, useState } from 'react';
import Modal from 'components/modal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const addModal = (modalContent) => {
    setModals((prevModals) => [...prevModals, modalContent]);
  };

  const removeModal = () => {
    setModals((prevModals) => prevModals.slice(0, -1));
  };

  return (
    <ModalContext.Provider value={{ addModal, removeModal }}>
      {modals.map((modalContent, index) => (
        <Modal key={index} handleClose={removeModal}>
          {modalContent}
        </Modal>
      ))}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
