import { gql } from '@apollo/client';

export default gql`
  mutation confirmUser($email: String!, $token: String!, $deviceToken: String, $deviceType: String) {
    confirmUser(input: { email: $email, token: $token, deviceToken: $deviceToken, deviceType: $deviceType }) {
      user {
        id
        email
      },
      expiresIn
    }
  }
`;
