import React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import { useError } from 'contexts/error';
import Form from 'components/user/profile/modal/plan/form';
import PlanMutation from 'mutations/plan/select';

const ModalPlan = ({ handleClose, onSuccess }) => {
  const setError = useError();

  const [planMutation, { loading }] = useMutation(PlanMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.plan.select');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.plan.select'/>
      </ModalTypography>
      <Form
        processing={loading}
        onSubmit={planMutation}
        onCancel={handleClose}/>
    </>
  );
};

export default ModalPlan;
