
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const OptionableAutocomplete = ({
  id,
  label,
  options,
  name,
  onChange,
  onSearch,
  onBlur,
  value,
  loading,
  error,
  helperText,
  loadingText,
  noOptionsText,
  disabled
}) => {
  const availableOptions = () => {
    if (value && !options.find(option => option.value === value.value)) {
      return [value, ...options];
    } else {
      return options;
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(_evt, value) => {
        const evt_value = value ? value.value : null;
        let evt = {
          target: {
            name: name,
            value: evt_value,
            id: id,
          }
        };
        onChange(evt, value);
      }}
      onBlur={onBlur}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      options={availableOptions()}
      onInputChange={(_e,v) => {
        onSearch(v);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          margin='dense'
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}/>
      )}/>
  );
};

export default OptionableAutocomplete;
