import React from 'react';
import { Button, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GoogleLink } from 'utils/link';

const GoogleLinkButton = ({ place }) => {
  return (
    <Button
      component={Link}
      target='_blank'
      rel='noopener noreferrer'
      href={GoogleLink(place)}>
        <FormattedMessage id='button.show_on_map'/>
    </Button>
  );
};

export default GoogleLinkButton;
