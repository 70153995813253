import Error from 'components/error';
import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={setError}>
      {error && <Error
        error={error}
        handleClose={() => {
          setError(null);
          window.location.reload();
        }} />
      }
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);


