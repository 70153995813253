import React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import { useError } from 'contexts/error';
import EditMutation from 'mutations/place/edit';
import Form from 'components/place/form';

const Edit = ({ handleClose, onSuccess, place }) => {
  const setError = useError();
  const [editMutation, { loading }] = useMutation(EditMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.place.edit');
    },
  });

  return (
    <>
      <ModalTypography variant='h6' align='center'>
        <FormattedMessage id='header.place.edit'/>
      </ModalTypography>
      <Form
        initialValues={{
          id: place.id,
          name: place.name,
          street: place.street,
          city: place.city,
          zipCode: place.zipCode,
        }}
        processing={loading}
        onSubmit={(values) => editMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Edit;
