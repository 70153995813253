import React from 'react';
import { Grid } from '@mui/material';
import { SectionGrid } from 'components/styles_components/container';

const EmptyInfo = ({ description, visible=false, children }) => {
  if (!visible) { return null; }

  return (
    <SectionGrid
      container
      justifyContent='center'
      alignItems='center'
      spacing={5}>
      <Grid item xs={12} textAlign='center'>
        {description}
      </Grid>
      <Grid item xs={12} textAlign='center'>
        {children}
      </Grid>
    </SectionGrid>
  );

};

export default EmptyInfo;
