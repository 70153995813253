import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Chip } from '@mui/material';
import InspectionState from 'utils/inspection_state';

const State = ({ state = {} }) => {
  return (
    <Chip
      size='small'
      color={InspectionState(state).color}
      label={<FormattedMessage id={`inspection.state.${state}`} />}/>
  );
};

export default State;
