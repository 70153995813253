import React from 'react';
import { Grid } from '@mui/material';
import { LabelSpan, ContentSpan } from 'components/styles_components/span';

const ShowItemRow = ({ label, color, children }) => {
  return (
    <Grid item xs={12} lg={12} color={color} justifyContent='center'>
      <LabelSpan>{label}:</LabelSpan> <ContentSpan>{children}</ContentSpan>
    </Grid>
  );
};

export default ShowItemRow;
